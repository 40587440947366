import request from "@/utils/request";
import {
  Station,
  StationDetail,
  DateRangeTypeHDM,
  DateRangeTypeDM,
  TimeValueResult,
} from "./@types";
import { dateRangeDec } from "@/utils/date";

//export const list = (): Promise<Station[]> => request.get('common/list/station')
export const list = (): Promise<Station[]> => {
  return request.get("/common/list/station");
};
export const detail = (id: number): Promise<StationDetail> =>
  request.get(`power/overview/station/detail/${id}`);

export const fetchPower = (
  stationId: number,
  dateRange: [string, string],
  queryType: DateRangeTypeHDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { stationId, startTime, endTime, queryType };
  return request.get("/data/station/power", { params });
};

export const fetchKw = (
  stationId: number,
  dateRange: [string, string],
  queryType?: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = {
    stationId,
    startTime,
    endTime,
    queryType: queryType ?? "hour",
  };
  return request.get("/data/station/kw", { params });
};

export const fetchCo2 = (
  stationId: number,
  dateRange: [string, string],
  queryType: DateRangeTypeHDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { stationId, startTime, endTime, queryType };
  return request.get("/data/station/co2", { params });
};

export const fetchHours = (
  stationId: number,
  dateRange: [string, string],
  queryType: DateRangeTypeDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { stationId, startTime, endTime, queryType };
  return request.get("/data/station/usableHours", { params });
};

export const fetchIncome = (
  stationId: number,
  dateRange: [string, string],
  queryType: DateRangeTypeDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { stationId, startTime, endTime, queryType };
  return request.get("/data/station/income", { params });
};

export const fetchSummaryList = () => {
  return request.get("/power/overview/station/list");
};
