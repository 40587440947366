import request from "@/utils/request";

// 查询站点用电配置可选设备列表
export const deviceList = (params: any): Promise<any> => {
  return request.get("/non-economic-energy/statistic/deviceList", { params });
};

// 保存站点用电配置
export const discreteAdd = (data: any): Promise<any> => {
  return request.post(
    "/non-economic-energy/statistic/stationSelfUseMeter",
    data,
  );
};

//查询站点用电配置
export const stationSelfUseMeter = (params: any): Promise<any> => {
  return request.get(`/non-economic-energy/statistic/stationSelfUseMeter`, {
    params,
  });
};

//本月数据概览
export const monthView = (params: any): Promise<any> => {
  return request.get(`/non-economic-energy/statistic/monthView`, { params });
};

//本月站点自用电 图表数据
export const chartSelfUse = (params: any): Promise<any> => {
  return request.get(`/non-economic-energy/statistic/chart/selfUse`, {
    params,
  });
};

//本月站点交流侧损耗 图表数据
export const chartAcLoss = (params: any): Promise<any> => {
  return request.get(`/non-economic-energy/statistic/chart/acLoss`, { params });
};

// 组织列表
export const organizationList = (): Promise<any> => {
  return request.get(`/common/list/customer`);
};
