import { defineStore } from "pinia";

//const storageSidebarShow = useStorage('sidebarShow', true)

export default defineStore("app", {
  state: () => ({
    sidebarShow: true,
    navAndSideBarShow: true,
    bodyTop: 0,
    bodyHeight: 800,
    sideMenuReady: false,
    mouseOverTimer: 0,
  }),
  getters: {},
  actions: {
    toggleSidebar(isShow: boolean | null = null) {
      if (null != isShow) {
        this.sidebarShow = isShow;
        return;
      }
      this.sidebarShow = !this.sidebarShow;
    },
    sidebarMouseOver() {
      clearTimeout(this.mouseOverTimer);
    },
    sidebarMouseOut() {
      this.mouseOverTimer = setTimeout(() => {
        this.sidebarShow = false;
      }, 2000) as any;
    },
    setNavAndSideBarShow(isShow: boolean | null = null) {
      // 设置导航栏和左侧菜单栏 是否显示
      if (isShow != null) {
        this.navAndSideBarShow = isShow;
        return;
      }
      this.navAndSideBarShow = !this.navAndSideBarShow;
    },
  },
});
