import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const dateRangeDec = (
  range: any[],
  format = "YYYY-MM-DD",
): [string, string] => {
  if (range.length !== 2) return ["", ""];
  return [dayjs(range[0]).format(format), dayjs(range[1]).format(format)];
};

export const format = (
  date: any,
  template = "YYYY-MM-DD HH:mm",
  type = "local",
): string => {
  if (type === "local") {
    return dayjs.utc(date).local().format(template);
  }
  return dayjs(date).format(template);
};
export const getDateBetween = (startTime: any, endTime: any) => {
  let result = [];
  let starts = startTime.split("-");
  let ends = endTime.split("-");
  let staYear = parseInt(starts[0]);
  let staMon = parseInt(starts[1]);
  let endYear = parseInt(ends[0]);
  let endMon = parseInt(ends[1]);
  while (staYear <= endYear) {
    if (staYear === endYear) {
      while (staMon <= endMon) {
        let str = staYear + "-" + (staMon >= 10 ? staMon : "0" + staMon);
        result.push(str);
        staMon++;
      }
      staYear++;
    } else {
      if (staMon > 12) {
        staMon = 1;
        staYear++;
      }
      let str = staYear + "-" + (staMon >= 10 ? staMon : "0" + staMon);
      result.push(str);
      staMon++;
    }
  }
  return result;
};
export const getYearBetween = (startTime: any, endTime: any) => {
  let result = [];
  let staYear = parseInt(startTime.substring(0, 4));
  let endYear = parseInt(endTime.substring(0, 4));
  while (staYear <= endYear) {
    let str = staYear;
    result.push(str);
    staYear++;
  }
  return result;
};
