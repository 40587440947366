<template>
  <div class="flex-1 flex-column">
    <div class="flex-row card-content">
      <SummaryBlock title="总-发电量" :value="total.value" :unit="total.unit" />
      <SummaryBlock
        title="尖-发电量"
        :value="total.value"
        :unit="total.unit"
        style="border-top: 4px solid #ff6d2c"
      />
      <SummaryBlock
        title="峰-发电量"
        :value="total.value"
        :unit="total.unit"
        style="border-top: 4px solid #ce90d1"
      />
      <SummaryBlock
        title="平-发电量"
        :value="total.value"
        :unit="total.unit"
        style="border-top: 4px solid #0db6d9"
      />
      <SummaryBlock
        title="谷-发电量"
        :value="total.value"
        :unit="total.unit"
        style="border-top: 4px solid #63bc7f"
      />
    </div>
    <ChartBase :option="option" class="flex-1 flex-column" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import dayjs from "dayjs";
import { wwhFormat } from "@/utils/unitFormat";
import np from "@/utils/number";

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: "hour",
  },
  data: {
    type: Array,
    default: [],
  },
});

const total = computed<{ value: string; unit: string }>(() => {
  if (props.data.length === 0) return { value: "", unit: "" };
  const t = props.data.map((d: any) => d.value).reduce((a, b) => a + b);
  return wwhFormat(t);
});

const tiems = computed(() => props.data.map((d: any) => d.time));
const values = computed(() => props.data.map((d: any) => d.value));

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: props.data.length > 12,
  };
});

enum dateType {
  hour = "YYYY-MM-DD HH:mm",
  day = "YYYY-MM-DD",
  month = "YYYY年MM月",
}

const tooltipFormatter = (tooltipData: any) => {
  const date = dayjs(tooltipData[0].name);
  return `
  <div style="margin-bottom:8px">${date.format(dateType[props.mode as keyof typeof dateType])}</div>
  <div>
    <span style="display:inline-block;margin-right:35px">总-发电量</span><span>${total.value.value} ${total.value.unit}</span>
  </div>
  ${tooltipData?.map((item: any) => (
    `
      <div>
        <span style="display:inline-block; width:8px;height:8px;border-radius: 50%;background: ${item.color}"></span>
        <span style="display:inline-block;margin-right:35px">${item.seriesName}</span><span>${np(item.value)} kWh</span>
      </div>
    `
  )).join('')}
`;
};

function xAxisLabelFormatter(name: any) {
  const date = dayjs(name);
  if (props.mode === "hour") {
    return `${date.format("HH:mm")}\n${date.format("MM-DD")}`;
  }
  if (props.mode === "day") {
    return `${date.format("MM-DD")}`;
  }
  if (props.mode === "month") {
    return `${date.format("MM月")}\n${date.format("YY年")}`;
  }
}

const option = computed(() => ({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
    selectedMode: false,
    icon: "rect",
    itemWidth: 12,
    itemHeight: 12,
    itemGap: 16,
    data: ["尖-发电量", "峰-发电量", "平-发电量", "谷-发电量"],
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  dataZoom: dataZoom.value,
  xAxis: {
    type: "category",
    data: tiems.value,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLabelFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "kWh",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      type: "bar",
      name: "谷-发电量",
      data: values.value,
      stack: "Ad",
      itemStyle: {
        color: "#63BC7F",
      },
      barMaxWidth: "24px",
      barMinWidth: "12px",
    },
    {
      type: "bar",
      name: "平-发电量",
      data: values.value,
      stack: "Ad",
      itemStyle: {
        color: "#0DB6D9",
      },
    },
    {
      type: "bar",
      name: "峰-发电量",
      data: values.value,
      stack: "Ad",
      itemStyle: {
        color: "#CE90D1",
      },
    },
    {
      type: "bar",
      name: "尖-发电量",
      data: values.value,
      stack: "Ad",
      itemStyle: {
        color: "#FF6D2C",
      },
    },
  ],
}));
</script>

<style lang="scss" scoped>
.card-content {
  gap: 8px;
  margin-bottom: 16px;
  .summary-block {
    flex: 1;
  }
}
</style>
