<template>
  <a class="tree-side-page_hamburg" @click="handleToggle">
    <svg
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="30"
    >
      <path
        d="M677.391515 873.916768c-7.86101 0-15.618586-2.999596-21.617778-8.895354L324.473535 533.721212c-11.998384-11.894949-11.998384-31.340606 0-43.235555L655.670303 159.288889c5.999192-5.999192 13.756768-8.895354 21.617778-8.895354 7.757576 0 15.618586 2.999596 21.617778 8.895354 11.894949 11.894949 11.894949 31.237172 0 43.235555L389.223434 512.103434 698.905859 821.785859c11.894949 11.998384 11.894949 31.340606 0 43.235555-5.895758 5.895758-13.756768 8.895354-21.514344 8.895354z m0 0"
        fill="rgba(255,255,255,0.75)"
      ></path>
    </svg>
  </a>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

// isCollapsed
const handleToggle = () => {
  emit("update:modelValue", !props.modelValue);
};
</script>

<style lang="scss" scoped>
.tree-side-page_hamburg {
  display: block;
  position: absolute;
  width: 20px;
  height: 66px;
  top: 50%;
  margin-top: -50px;
  background: #383839;
  // background: rgba(255, 255, 255, 0.1);
  left: 240px;
  display: flex;
  cursor: pointer;
  //&:hover {
  //  background: #383839 !important;
  //}
  & > svg {
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>
