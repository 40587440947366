import { defineStore } from "pinia";
import { list as fetchStations } from "@/api/station";
import { Station } from "@/api/@types";

export default defineStore("public.station.list", {
  state: () => ({
    list: [] as Station[],
  }),
  actions: {
    load() {
      fetchStations().then(list => {
        this.list = list || [];
      });
    },
  },
});
