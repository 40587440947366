<template>
  <mx-select
    v-model="currentValue"
    :disabled="!combinerBoxId"
    placeholder="请选择"
    :style="{ width }"
  >
    <mx-option
      v-for="item in options"
      :key="item.deviceId"
      :label="item.name"
      :value="item.deviceId"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchInverters } from "@/api/inverter";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  areaId: {
    type: Number,
    default: 0,
    require: true,
  },
  combinerBoxId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: Number,
    default: null,
  },
  defaultValue: {
    type: Number,
    default: null,
  },
  autoSelectFirst: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "158px",
  },
});
const emit = defineEmits(["update:modelValue", "itemChange"]);
const currentValue = computed<number>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

watch(
  () => props.combinerBoxId,
  () => {
    if (!props.combinerBoxId) {
      emit("update:modelValue", null);
      return;
    }

    fetchInverters(props.stationId, props.areaId, props.combinerBoxId).then(
      list => {
        options.value = list.filter(d => d.deviceId);

        if (
          route.query?.deviceId &&
          list.filter((item: any) => {
            return item.id == route.query?.deviceId;
          }).length
        ) {
          const itemData = list.filter((item: any) => {
            return item.id == route.query?.deviceId;
          });
          emit("update:modelValue", itemData[0].deviceId);
          return;
        }

        // 保持先前的值
        if (
          props.modelValue &&
          list.map(d => d.deviceId).includes(props.modelValue)
        ) {
          return;
        } else {
          // 需干掉先前的值
          emit("update:modelValue", null);
        }

        // 默认值
        if (
          props.defaultValue &&
          list.map(d => d.deviceId).includes(props.defaultValue)
        ) {
          emit("update:modelValue", props.defaultValue);
          return;
        }

        if (props.autoSelectFirst && list.length > 0) {
          emit("update:modelValue", list[0]["deviceId"]);
        }
      },
    );
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  newVal => {
    nextTick(() => {
      const item = options.value.find(d => d.deviceId == newVal);
      emit("itemChange", item);
    });
  },
);
</script>
