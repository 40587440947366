import { defineStore } from "pinia";

type QueryState = {
  [key: string]: object;
};

export default defineStore("public.query.caches", {
  state: () => ({
    cache: {} as QueryState,
  }),
  getters: {
    get: state => {
      return (name: string, def = null) => {
        if (!name) return def;
        return name in state.cache ? state.cache[name] : def;
      };
    },
  },
  actions: {
    set(name: string, data: any) {
      if (!name) return;
      this.cache[name] = data;
      // this.cache = JSON.parse(JSON.stringify(this.cache))
      // this.cache = { ...this.cache, [name]: data };
    },
  },
  persist: {
    enabled: true,
  },
});
