import { ref, onMounted, onUnmounted } from "vue";
import screenfull from "screenfull";
function useScreenfull() {
  //全屏
  const isFullscreen = ref(false);
  const bodyHeight = ref(0);
  let screenElement = ref(""); // 用于 F11 按键使用
  onMounted(() => {
    window.addEventListener("resize", currentScreen);
    window.addEventListener("keydown", KeyDown, true);
  });

  onUnmounted(() => {
    window.removeEventListener("resize", currentScreen);
    window.removeEventListener("keydown", KeyDown);
  });

  // F11全屏(实际上是有问题的)
  const KeyDown = (event: any) => {
    if (event.keyCode === 122) {
      event.returnValue = false;
      screenElement.value
        ? screenfullClick(screenElement.value)
        : screenfullClick();
    }
  };

  // 浏览器改变触发
  const currentScreen = () => {
    if (screenfull.isFullscreen !== isFullscreen.value)
      isFullscreen.value = screenfull.isFullscreen;

    changeHeight();
  };

  const changeHeight = () => {
    if (screenfull.isFullscreen) {
      bodyHeight.value = document.documentElement.clientHeight - 120;
    } else {
      bodyHeight.value = document.documentElement.clientHeight - 220;
    }
  };

  //  点击切换全屏
  const screenfullClick = (screenEle: string = ".app-body") => {
    // const currentPath = window.location.pathname;
    let ele = null;
    // screenElement.value=screenEle;
    // if(screenEle!='.app-body' || currentPath.includes('/cer')){
    //   ele = document.querySelector('body'); //指定全屏区域元素
    //   appStore.setNavAndSideBarShow(isFullscreen.value);
    // }else{
    ele = document.querySelector(screenEle); //指定全屏区域元素
    // }

    if (screenfull.isEnabled) {
      // @ts-ignore
      screenfull.toggle(ele);
    }
    changeHeight();
  };
  changeHeight();

  return {
    isFullscreen,
    bodyHeight,
    screenfullClick,
  };
}

export default useScreenfull;
