import request from "@/utils/request";
import { GridPoint, HVCabinet } from "./@types";

export const list = (stationId: number): Promise<GridPoint[]> => {
  const params = { stationId };
  return request.get("/common/list/gridPoint", { params });
};

export const fetchTransformers = (
  stationId: number,
  gridPointId: number,
): Promise<HVCabinet[]> => {
  const params = { stationId, gridPointId };
  return request.get("/common/list/transformer", { params });
};
