import { ref, onMounted, onUnmounted, watch, nextTick } from "vue";
import useAppStore from "@/store/app";

const sideBarWidth = 208;
export default (screenWidth = 1920, screenHeight = 1080, topSpaceing = 50) => {
  const initBoxWidth = ref(screenWidth);
  const initBoxHeight = ref(screenHeight);

  const appStore = useAppStore();

  const scale = ref(1);
  const boxWidth = ref(screenWidth);
  const boxHeight = ref(screenHeight);

  let appContainer: HTMLDivElement | null | undefined = null;
  let bodyBorderParent: HTMLDivElement | null | undefined = null;

  onMounted(() => {
    appContainer = document.querySelector<HTMLDivElement>(".app-container");
    bodyBorderParent = appContainer?.querySelector<HTMLDivElement>(
      ".body-border-parent",
    );

    window.addEventListener("resize", onResize);
    doScale();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", onResize);
    appContainer?.classList.remove("scale-enabled");
  });

  const doScale = () => {
    const boxRadio = initBoxWidth.value / initBoxHeight.value;

    const viewWidth = appStore.sidebarShow
      ? window.innerWidth - sideBarWidth
      : window.innerWidth;

    const viewHeihgt = window.innerHeight - topSpaceing;

    if (viewWidth / viewHeihgt > boxRadio) {
      // 纵向适应
      boxHeight.value = viewHeihgt;
      boxWidth.value = (viewHeihgt / initBoxHeight.value) * initBoxWidth.value;
      scale.value = boxWidth.value / initBoxWidth.value;
    } else {
      // 横向适应
      boxWidth.value = viewWidth;
      boxHeight.value = (viewWidth / initBoxWidth.value) * initBoxHeight.value;
      scale.value = boxHeight.value / initBoxHeight.value;
    }

    appContainer?.classList.add("scale-enabled");
    appContainer?.style.setProperty("--scale", `scale(${scale.value})`);
    appContainer?.style.setProperty("--scale-value", `${scale.value}`);

    bodyBorderParent?.style.setProperty("--width", `${boxWidth.value}px`);
    bodyBorderParent?.style.setProperty("--height", `${boxHeight.value}px`);
  };

  let timer: NodeJS.Timeout = null as any;
  const onResize = () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      doScale();
    }, 10);
  };

  watch(
    () => appStore.sidebarShow,
    () => {
      nextTick(() => {
        doScale();
      });
    },
  );

  return {};
};
