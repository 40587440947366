<template>
  <div class="x-process-bar">
    <!-- <div class="track">
      <div class="value-bar" :style="{ width: styleWidth+'%'}"></div>
    </div> -->
    <el-progress class="track" :percentage="strValue" :show-text="false" />
    <div class="value-text" v-if="value === null">--</div>
    <x-clip-text v-else class="value-text"> {{ strValue }}%</x-clip-text>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  value?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  value: null,
});

const strValue = computed(() => (props.value ? props.value : 0));
</script>

<style lang="scss" scoped>
.x-process-bar {
  // .value-bar {
  //   height: 8px;
  //   background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
  //   border-radius: 4px;
  //   max-width: 100%;
  // }
  .value-text {
    margin-top: 8px;
    font-size: 22px;
    line-height: 30px;
  }
  :deep(.el-progress) {
    .el-progress-bar__outer {
      width: 100%;
      height: 8px !important;
      background: #214172;
      border-radius: 4px;
    }
    .el-progress-bar__inner {
      background: linear-gradient(270deg, #51dbff 0%, #51a1ff 100%);
    }
  }
}
</style>
