export const hoursFormat = (value: number) => {
  if (undefined === value || null === value) return "";
  const lss = [];
  let hours = Math.trunc(value);
  if (hours > 24) {
    const days = Math.trunc(value / 24);
    lss.push(`${days}天`);
    hours = Math.trunc(hours - days * 24);
  }
  if (hours > 0) {
    lss.push(`${hours}小时`);
  }
  const minutes = Math.trunc((value - Math.trunc(value)) * 60);
  if (minutes > 0) {
    lss.push(`${minutes}分`);
  }
  return lss.join("");
};

// kw
export const kwFormat = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 1000) {
    return { value: (value / (1000 * 1000)).toFixed(2), unit: "GW" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "MW" };
  }
  return { value: value.toFixed(2), unit: "kW" };
};

// kwh
export const kwhFormat = (value: number, dec = 2) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 1000) {
    return { value: (value / (1000 * 1000)).toFixed(dec), unit: "GWh" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(dec), unit: "MWh" };
  }
  return { value: value.toFixed(dec), unit: "kWh" };
};

// 万Wh
export const wwhFormat = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);

  if (value > 10 * 10000) {
    // 大于十万转万kWh单位
    return { value: (value / 10000).toFixed(2) + "万", unit: "kWh" };
  }
  return { value: value.toFixed(2), unit: "kWh" };
};
// 万元
export const wyFormat = (value: number, dec = 2) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);

  if (value > 1000 * 1000) {
    // 大于十万转万kWh单位
    return { value: (value / 10000).toFixed(dec), unit: "万元" };
  }
  return { value: value.toFixed(dec), unit: "元" };
};
// 万Wh
export const wwhFormats = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);

  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "MWh" };
  }
  return { value: value.toFixed(2), unit: "kWh" };
};
// 万W
export const wwFormat = (value: number, dec = 2) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);

  if (value > 1000) {
    // 大于十万转万kWh单位
    return { value: (value / 1000).toFixed(dec), unit: "MW" };
  }
  return { value: value.toFixed(dec), unit: "kW" };
};

// kAh
export const kahFormat = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 1000) {
    return { value: (value / (1000 * 1000)).toFixed(2), unit: "GAh" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "MAh" };
  }
  return { value: value.toFixed(2), unit: "kAh" };
};

// kg to t
export const co2Format = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  return { value: (value / 1000).toFixed(2), unit: "t" };
};

// kg to 万t
export const co2NewFormat = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 10000) {
    return { value: (value / (1000 * 1000)).toFixed(2), unit: "万t" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "t" };
  }
  return { value: value.toFixed(2), unit: "kg" };
};

// t to 万
export const co2tToWtNewFormat = (value: number, unit: string = "t") => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 100000) {
    return { value: (value / 10000).toFixed(2), unit: "万" + unit };
  }
  return { value, unit };
  // if (value > 1000) {
  //   return { value: (value / 1000).toFixed(2), unit: "t" };
  // }
  // return { value: value.toFixed(2), unit: "kg" };
};

export const wm2Format = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 1000 * 1000) {
    return { value: (value / (1000 * 1000 * 1000)).toFixed(2), unit: "GW/㎡" };
  }
  if (value > 1000 * 1000) {
    return { value: (value / 1000).toFixed(2), unit: "MW/㎡" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "kW/㎡" };
  }
  return { value: value.toFixed(2), unit: "W/㎡" };
};

export const whm2Format = (value: number) => {
  if (undefined === value || null === value) return { value: "--", unit: "" };
  if (typeof value === "string") value = Number(value);
  if (value > 1000 * 1000 * 1000) {
    return { value: (value / (1000 * 1000 * 1000)).toFixed(2), unit: "GWh/㎡" };
  }
  if (value > 1000 * 1000) {
    return { value: (value / (1000 * 1000)).toFixed(2), unit: "MWh/㎡" };
  }
  if (value > 1000) {
    return { value: (value / 1000).toFixed(2), unit: "kWh/㎡" };
  }
  return { value: value.toFixed(2), unit: "Wh/㎡" };
};
