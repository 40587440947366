<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import np from "@/utils/number";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
  unit: {
    type: String,
    default: "kV",
  },
});

const times = computed(() => props.data.map((d: any) => d.time));
const values = computed(() => props.data.map((d: any) => d.value));

const tooltipFormatter = ([d]: Array<any>) => {
  if (undefined === d.value || null === d.value) return "";
  const time = dayjs(d.name).format("YYYY-MM-DD HH:mm");
  return `<div style="margin-bottom:8px">${time}</div><span style="display:inline-block;margin-right:35px">电压</span> <span style="color:#4A90E2">${np(
    d.value,
  )} ${props.unit}</span>`;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: props.data.length > 12,
    filterMode: "none",
  };
});
const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: props.unit,
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: values,
      type: "line",
      smooth: true,
      symbol: "none",
      name: "电压",
      connectNulls: true,
    },
  ],
});
</script>
