import request from "@/utils/request";

// 站点级运营概览内容
export const operateOverview = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/operate/overview", { params });
};
//站点安全监控
export const operateSecurityMonitor = (params: any): Promise<any> => {
  return request.get("/power/overview/station/securityMonitor", { params });
};
// 光伏收益统计分析
export const incomeAnalysis = (params: any): Promise<any> => {
  return request.get("/data/station/incomeStatistic", { params });
};

// 报警记录导出
export const exportIncome = (params: any): Promise<any> => {
  return request.get("/data/station/incomeStatistic/export", {
    params,
    responseType: "arraybuffer",
  });
};
