import request from "@/utils/request";

/**
 * @description:系统效率分析列表
 */
export const getPvPrList = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/pv/pr/list", { params });
};

/**
 * @description:光伏站系统效率分布
 */
export const postPvPrDistribution = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/pv/pr/distribution", { params });
};

/**
 * @description:系统效率变化趋势
 */
export const getPvPrTrend = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/pv/pr/trend", { params });
};
