import request from "@/utils/request";

/**
 * @description:直方图
 */
export const getInverterBar = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/station/dispersion/inverter/count", {
    params,
  });
};

/**
 * @description:逆变器离散率
 */
export const postDistributionCard = (params: any): Promise<any> => {
  if (!params.orderMethod) {
    params.orderMethod = "1";
  }
  return request.get("/performanceanalysis/inverter/dispersion/list", {
    params,
  });
};

/**
 * @description:光伏站综合离散率列表
 */
export const getDispersionList = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/station/dispersion/list", {
    params,
  });
};

/**
 * @description:组串电流信息
 */
export const getCurrentCardDetail = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/string/current/list", { params });
};

/**
 * @description:离散率表现
 */
export const analysisChart = (params: any): Promise<any> => {
  return request.get(
    "/performanceanalysis/station/dispersion/inverter/analysisChart",
    { params },
  );
};

//离散率--组串电流详情
export const analysisDetail = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/string/current/detail/list", {
    params,
  });
};

//离散率--逆变器离散率趋势
export const dispersionTrend = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/inverter/dispersion/trend", {
    params,
  });
};

//光伏站或片区的分析结论
export const analysisResult = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/analysisResult", { params });
};
