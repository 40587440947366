import request from "@/utils/request";
import { Area, DateRangeTypeHDM, TimeValueResult } from "./@types";
import { dateRangeDec } from "@/utils/date";

export const list = (stationId: number): Promise<Area[]> => {
  const params = { stationId };
  return request.get("/common/list/area", { params });
};

export const fetchPower = (
  stationId: number,
  areaId: number,
  dateRange: [string, string],
  queryType: DateRangeTypeHDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { stationId, areaId, startTime, endTime, queryType };
  return request.get("/data/area/power", { params });
};

export const fetchKw = (
  stationId: number,
  areaId: number,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = {
    stationId,
    areaId,
    startTime,
    endTime,
    queryType: queryType ?? "day",
  };
  return request.get("/data/area/kw", { params });
};
