<template>
  <mx-select
    v-model="currentValue"
    :disabled="!loaded || !stationId"
    placeholder="请选择"
    fit-input-width
    :style="{ width }"
    :clearable="isClearable"
  >
    <mx-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchList } from "@/api/area";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({
  // 依赖指定的光伏站
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  // 当前值
  modelValue: {
    type: Number,
    default: null,
  },
  // 默认的值，加载列表过后才能赋值，不然会直接显示value值，不美观
  defaultValue: {
    type: Number,
    default: null,
  },
  // 很多场景需要默认选择第一项，但不是所有场景
  autoSelectFirst: {
    type: Boolean,
    default: true,
  },
  // 给指定宽度，或100%等
  width: {
    type: String,
    default: "158px",
  },
  isClearable: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "itemChange"]);
const currentValue = computed<number>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

const loaded = ref(false);
watch(
  () => props.stationId,
  () => {
    if (!props.stationId) {
      emit("update:modelValue", null);
      return;
    }

    loaded.value = false;
    fetchList(props.stationId).then(list => {
      loaded.value = true;

      options.value = list;
      if (
        route.query?.areaId &&
        list.filter((item: any) => {
          return item.id == route.query?.areaId;
        }).length
      ) {
        const queryId = Number(route.query?.areaId);
        emit("update:modelValue", queryId);
        return;
      }
      // 保持先前的值
      if (props.modelValue && list.map(d => d.id).includes(props.modelValue)) {
        return;
      } else {
        // 需干掉先前的值
        emit("update:modelValue", null);
      }

      // 默认值
      if (
        props.defaultValue &&
        list.map(d => d.id).includes(props.defaultValue)
      ) {
        emit("update:modelValue", props.defaultValue);
        return;
      }

      if (props.autoSelectFirst && list.length > 0) {
        emit("update:modelValue", list[0]["id"]);
      }
    });
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  newVal => {
    nextTick(() => {
      const item = options.value.find((d: any) => d.id == newVal);
      emit("itemChange", item);
    });
  },
);
</script>
