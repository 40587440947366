import { ref, Ref } from "vue";
import dayjs from "dayjs";
import request from "@/utils/request";
import linkClick from "@/utils/linkClick";
import { MxMessage } from "@maxtropy/v-components";

type StartResp = { key: string };
type CheckResp = {
  completed: boolean;
  error: boolean;
  fileUrl: string;
  key: string;
};

const handTrack = (key: string, cacheKey: string, loading: Ref<boolean>) => {
  const url = `/performanceanalysis/inverter/checkComplete/${key}`;

  request.get<any, CheckResp>(url).then(resp => {
    // 后端报错
    if (resp.error) {
      if (loading.value) loading.value = false;
      return MxMessage({
        message: "导出时出现服务器异常",
      });
    }
    // 未完成待会儿继续检测
    if (!resp.completed) {
      return setTimeout(() => {
        handTrack(key, cacheKey, loading);
      }, 5000);
    }
    if (loading.value) loading.value = false;
    localStorage.removeItem(cacheKey);
    // 无下载地址
    if (!resp.fileUrl) {
      return MxMessage({
        message: "导出出现未知错误: 未发现的导出文件流",
      });
    }
    // 去下载
    linkClick(resp.fileUrl);
  });
};

export default (name: string) => {
  const loading = ref(false);
  const cacheKey = `downloadTask.${name}`;

  const apply = (url: string, params: any = {}) => {
    loading.value = true;
    return request.get<any, StartResp>(url, { params }).then(resp => {
      const time = dayjs().format("YYYY-MM-DD HH:mm:ss");
      const key = `${resp.key}###${time}`;
      localStorage.setItem(cacheKey, key);
      handTrack(resp.key, cacheKey, loading);
      return resp.key;
    });
  };
  const track = () => {
    const trackKey = localStorage.getItem(cacheKey);
    if (trackKey) {
      const tks = trackKey.split("###");
      // 旧的未带过期时间的立即失效
      if (tks.length < 2) {
        loading.value = false;
        localStorage.removeItem(cacheKey);
        return;
      }
      // 已过期
      const trackTime = tks[1];
      if (dayjs().subtract(1, "hour").isAfter(dayjs(trackTime))) {
        loading.value = false;
        localStorage.removeItem(cacheKey);
        return;
      }

      loading.value = true;
      handTrack(trackKey, cacheKey, loading);
    }
  };
  track();

  return {
    loading,
    apply,
  };
};
