<template>
  <mx-select
    v-model="currentValue"
    :disabled="!loaded"
    placeholder="请选择"
    fit-input-width
    style="width: 158px"
  >
    <mx-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { list as fetchList } from "@/api/gridPoint";

const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);
const currentValue = computed<number>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

const loaded = ref(false);
watch(
  () => props.stationId,
  () => {
    if (!props.stationId) return;
    loaded.value = false;
    fetchList(props.stationId).then(list => {
      options.value = list;
      emit("update:modelValue", list.length > 0 ? list[0]["id"] : null);
      loaded.value = true;
    });
  },
  { immediate: true },
);
</script>
