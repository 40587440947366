<template>
  <div class="cx-input">
    <div class="input__wrapper">
      <input
        :value="props.modelValue"
        class="input__inner"
        type="text"
        autocomplete="off"
        tabindex="0"
        :placeholder="props.placeholder"
        @input="handleInput"
        @change="handleChange"
      />
    </div>
    <!-- append slot -->
    <slot name="append" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  useChangeEvent: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "请输入",
  },
});
const emit = defineEmits(["update:modelValue"]);
const handleInput = (e: any) => {
  if (!props.useChangeEvent) emit("update:modelValue", e.target.value);
};
const handleChange = (e: any) => {
  if (props.useChangeEvent) emit("update:modelValue", e.target.value);
};
</script>

<style lang="scss">
.cx-input {
  border-radius: 2px;
  background: rgba(74, 144, 226, 0.06);
  border: 1px solid rgba(0, 173, 255, 0.5019607843137255);
  .input__inner {
    width: 100%;
    outline: 0 !important;
    background: transparent;
    box-sizing: border-box;
    padding-left: 10px;
    border-width: 0;
    height: 32px;
    color: #fff;
  }
}
</style>
