<template>
  <mx-select
    v-model="currentValue"
    placeholder="请选择"
    :style="{ width }"
    :filterable="props.filterable"
    :clearable="isClearable"
  >
    <mx-option
      v-for="item in stationStore.list"
      :key="item.id"
      :label="item.stationName"
      :value="item.id"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { computed, watch, ref, nextTick } from "vue";
import useStationStore from "@/store/station";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  modelValue: {
    type: Number,
    default: undefined,
  },
  defaultValue: {
    type: Number,
    default: null,
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  autoSelectFirst: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "200px",
  },
  isClearable: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "itemChange", "selectFirst"]);

const stationStore = useStationStore();

const currentValue = computed<number | undefined>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

watch(
  () => stationStore.list,
  () => {
    if (stationStore.list.length > 0) {
      if (route.query?.stationId || 0) {
        const queryStationId = Number(route.query?.stationId);
        emit("update:modelValue", queryStationId);
        emit("selectFirst", stationStore.list[0]);
        return;
      }
      if (
        props.defaultValue &&
        stationStore.list.map(d => d.id).includes(props.defaultValue)
      ) {
        emit("update:modelValue", props.defaultValue);
        emit("selectFirst", stationStore.list[0]);
        return;
      }
      if (
        props.modelValue &&
        stationStore.list.map(d => d.id).includes(props.modelValue)
      ) {
        emit("update:modelValue", props.modelValue);
        emit("selectFirst", stationStore.list[0]);
        return;
      }
      if (props.autoSelectFirst && stationStore.list.length > 0) {
        emit("update:modelValue", stationStore.list[0].id);
        emit("selectFirst", stationStore.list[0]);
      }
    }
  },
  {
    immediate: true,
  },
);

watch(
  () => props.modelValue,
  newVal => {
    nextTick(() => {
      const itemData = stationStore.list.filter(item => {
        return item.id === newVal;
      });
      emit("itemChange", itemData.length ? itemData[0] : {});
    });
  },
);

onMounted(() => {
  stationStore.load();
});
</script>
