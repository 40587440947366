<template>
  <mx-checkbox-group
    :model-value="modelValue"
    @change="emit('update:modelValue', $event)"
  >
    <mx-checkbox
      v-for="item in props.options"
      :key="item.value"
      :label="item.value"
      >{{ item.label }}</mx-checkbox
    >
  </mx-checkbox-group>
</template>

<script setup lang="ts">
import { withDefaults } from "vue";

type ModelValues = number[];
type Option = { value: number | string; label: string };
interface Props {
  options: Option[];
  modelValue: ModelValues;
}
const props = withDefaults(defineProps<Props>(), {
  options: [] as Option[],
  modelValue: [] as ModelValues,
});

const emit = defineEmits(["update:modelValue"]);
</script>
