import request from "@/utils/request";

// 今日功率曲线
export const todayPowerChart = (params: any): Promise<any> => {
  return request.get("/data/station/kw", { params });
};

// 今日发电量
export const todayElectricChart = (params: any): Promise<any> => {
  return request.get("/data/station/power", { params });
};

//当月收益
export const fetchIncome = (params: any): Promise<any> => {
  return request.get("/data/station/income", { params });
};
