/**
 @description 监控元素resize指令
 @example
  <template>
    <div v-size-ob="handleSizeOb">...</div>
  </template>
  <script>
    const handleSizeOb = ({target, width, height}) => {
      ...
    }
  </script>
*/
import { Directive } from "vue";
// 三方库解决了浏览器兼容问题
import ResizeObserver from "resize-observer-polyfill";

// WeakMap做元素隐射才不会影响垃圾回收
const map = new WeakMap();

// 公共的ResizeObserver(多节点共享)
const observer = new ResizeObserver(entries => {
  for (const entry of entries) {
    const handler = map.get(entry.target);
    if (handler) {
      const box = entry.borderBoxSize[0];
      handler({
        width: box.inlineSize,
        height: box.blockSize,
        target: entry.target,
      });
    }
  }
});

export default {
  mounted(el: any, binding: any) {
    observer.observe(el);
    map.set(el, binding.value);
  },
  unmounted(el: any) {
    observer.unobserve(el);
  },
} as Directive;
