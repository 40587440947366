<template>
  <mx-select v-model="currentValue" placeholder="请选择" style="width: 158px">
    <mx-option
      v-for="item in options"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchInverters } from "@/api/inverter";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  areaId: {
    type: Number,
    default: 0,
    require: true,
  },
  combinerBoxId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: Number,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue", "itemChange"]);
const currentValue = computed<number>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

watch(
  () => [props.stationId, props.areaId, props.combinerBoxId],
  () => {
    if (props.stationId && props.areaId && props.combinerBoxId) {
      fetchInverters(props.stationId, props.areaId, props.combinerBoxId).then(
        list => {
          options.value = list;
          if (
            route.query?.deviceId &&
            list.filter((item: any) => {
              return item.id == route.query?.deviceId;
            }).length
          ) {
            const queryId = Number(route.query?.deviceId);
            emit("update:modelValue", queryId);
            return;
          }
          emit("update:modelValue", list.length > 0 ? list[0].id : null);
        },
      );
    }
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  newVal => {
    nextTick(() => {
      const item = options.value.find((item: any) => item.id == newVal);
      emit("itemChange", item);
    });
  },
);
</script>
