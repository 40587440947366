import request from "@/utils/request";
import { CombinerBox } from "./@types";

export const list = (
  stationId: number,
  areaId: number,
): Promise<CombinerBox[]> => {
  const params = { stationId, areaId };
  return request.get("common/list/combinerbox", { params });
};
