<template>
  <mx-select
    v-model="currentValue"
    placeholder="请选择"
    :style="{ width }"
    multiple
    filterable
    collapse-tags
    :multiple-limit="limit"
    collapse-tags-tooltip
    :clearable="isClearable"
    :max-collapse-tags="1"
  >
    <mx-option
      v-for="item in stationStore.list"
      :key="item.id"
      :label="item.stationName"
      :value="item.id"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { computed, watch } from "vue";
import useStationStore from "@/store/station";
import { useRoute } from "vue-router";

const route = useRoute();

const props = defineProps({
  modelValue: {
    type: Array,
    default: [],
  },
  defaultValue: {
    type: Number,
    default: null,
  },
  autoSelectFirst: {
    type: Boolean,
    default: true,
  },
  width: {
    type: String,
    default: "200px",
  },
  isClearable: {
    type: Boolean,
    default: false,
  },
  limit: {
    type: Number,
    default: 10,
  },
});
const emit = defineEmits(["update:modelValue", "itemChange"]);
const stationStore = useStationStore();

const currentValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

watch(
  () => stationStore.list,
  () => {
    if (stationStore.list.length > 0) {
      if (route.query?.stationId || 0) {
        const queryStationId = Number(route.query?.stationId);
        emit("update:modelValue", [queryStationId]);
        return;
      }
      if (props.defaultValue) {
        emit("update:modelValue", props.defaultValue);
        return;
      }
      if (props.modelValue) {
        emit("update:modelValue", props.modelValue);
        return;
      }
    }
  },
  {
    immediate: true,
  },
);
</script>

<style scoped lang="scss">
:deep(.el-select__tags-text) {
  max-width: 40px !important;
}
</style>
