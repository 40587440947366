import request from "@/utils/request";

// 光伏健康评估列表
export const healthList = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/health/list", { params });
};

// 光伏健康评估列表
export const detailId = (id: number): Promise<any> => {
  return request.get(`/power/overview/station/detail/${id}`);
};

//光伏站数据-可用小时数
export const usableHours = (params: any): Promise<any> => {
  return request.get(`/data/station/usableHours`, { params });
};

//光伏站数据-气象信息
export const weather = (params: any): Promise<any> => {
  return request.get(`/data/station/weather`, { params });
};

//光伏站数据-日度峰值负荷
export const peakLoad = (params: any): Promise<any> => {
  return request.get(`/data/station/peakLoad`, { params });
};
