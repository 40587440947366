export default (url: string) => {
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("target", "_blank");
  a.setAttribute("id", "js_a_link");
  document.body.appendChild(a);
  a.click();
  setTimeout(() => {
    if (a) {
      document.body.removeChild(a);
    }
  }, 1000);
};
