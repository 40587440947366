<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase
      :option="option"
      :update-options="{ notMerge: true }"
      @click="onClick"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import merge from "merge";
import * as echarts from "echarts";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  unit: {
    type: String,
    default: "",
  },
  color: {
    type: Array,
    default: () => ["#CE90D1", "#0DB6D9", "#63BC7F"],
  },
  tooltip: {
    type: Object,
    default: () => ({}),
  },
  yAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  xAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  right: {
    type: Number,
    default: 70,
  },
  isLegend: {
    type: Boolean,
    default: false,
  },
  grid: {
    type: Object,
    default: () => ({}),
  },
  dataZoom: {
    type: Array,
    default: () => [],
  },
});

const serieCfgs = {
  data: [],
  type: "line",
};

const areaStyle = {
  opacity: 0.8,
  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    {
      offset: 0,
      color: "rgb(128, 255, 165)",
    },
    {
      offset: 1,
      color: "rgb(1, 191, 236)",
    },
  ]),
};

const times = computed(() => {
  if (props.data.length === 0) return [];
  // @ts-ignore
  return props.data[0].data.map((d: any) => d.time);
});
const series = computed(() => {
  return props.data.map((d: any) => {
    return {
      ...d,
      name: d.name,
      // areaStyle:d.isCustomColor&&d.areaStyle?d.areaStyle:areaStyle,
      data: d.data.map((d: any) => {
        return {
          value: d.value,
          ...d,
        };
      }),
    };
  });
});
const option = computed(() => ({
  grid: {
    x: 40,
    y: 60,
    right: props.right,
    bottom: 40,
    containLabel: true,
    ...props.grid,
  },
  legend: {
    show: props.isLegend,
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    confine: true,
    ...props.tooltip,
  },
  dataZoom: props.dataZoom,
  color: props.color,
  xAxis: {
    type: "category",
    data: times.value,
    axisLabel: {
      lineHeight: 20,
      align: "center",
      color: "#FFFFFF",
    },
    axisTick: {
      alignWithLabel: true,
    },
    ...props.xAxisOptions,
  },
  yAxis: {
    type: "value",
    name: props.unit,
    axisLabel: {
      lineHeight: 20,
      color: "#FFFFFF",
    },
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
    ...props.yAxisOptions,
  },
  series: series.value.map(d => merge({}, serieCfgs, d)),
}));
const emit = defineEmits(["click"]);
const onClick = (e: any) => {
  emit("click", e);
};
</script>
