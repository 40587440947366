<template>
  <mx-select
    v-model="currentValue"
    placeholder="请选择"
    multiple
    filterable
    collapse-tags
    collapse-tags-tooltip
    style="width: 200px"
  >
    <mx-option
      v-for="item in options"
      :key="item.deviceId"
      :label="item.name"
      :value="item.deviceId"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed, nextTick } from "vue";
import { list as fetchList } from "@/api/inverter";

const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  areaId: {
    type: Number,
    default: 0,
    require: true,
  },
  combinerBoxId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: Array,
    default: () => [] as string[],
  },
});
const emit = defineEmits(["update:modelValue", "itemsChange"]);
const currentValue = computed<string[]>({
  get: () => props.modelValue as string[],
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);
const autoSelect8Items = () => {
  const ids = [...options.value].splice(0, 8).map((d: any) => d.deviceId);
  emit("update:modelValue", ids);
};

watch(
  () => props.combinerBoxId,
  () => {
    if (!props.combinerBoxId) {
      emit("update:modelValue", []);
      return;
    }
    fetchList(props.stationId, props.areaId, props.combinerBoxId).then(list => {
      options.value = list.filter(d => d.deviceId);
      autoSelect8Items();
    });
  },
  { immediate: true },
);

watch(
  () => props.modelValue,
  newVal => {
    nextTick(() => {
      const items = options.value.filter(d => newVal.includes(d.deviceId));
      emit("itemsChange", items);
    });
  },
);
</script>
<style lang="scss" scoped>
:deep(.el-select__input) {
  display: none !important;
}
</style>
