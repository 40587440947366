import request from "@/utils/request";

/**
 * @description:光伏站绩效列表
 */
export const getPvList = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/pv/list", { params });
};

/**
 * @description:配置光伏站年度发电计划
 */
export const postPvPlan = (params: any): Promise<any> => {
  return request.post("/performanceanalysis/pv/plan", params);
};

/**
 * @description:年度累计发电情况
 */
export const getPvChart = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/pv/charts", { params });
};
