<template>
  <ChartLinevoltage :data="data" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { fetchLinevoltage } from "@/api/HVCabinet";
import { LineVoltageResult } from "@/api/@types";

type QueryType = {
  deviceId: string;
  dateRange: [string, string];
};

interface ChartProps {
  query: QueryType;
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
// @ts-ignore
const data = ref<LineVoltageResult[]>({ ab: [], bc: [], ca: [] });
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (!query.deviceId || query.dateRange.length !== 2) {
      // @ts-ignore
      data.value = { ab: [], bc: [], ca: [] };
      return;
    }
    loading.value = true;
    fetchLinevoltage(query.deviceId, query.dateRange).then(list => {
      data.value = list as any;
      loading.value = false;
    });
  },
  { immediate: true },
);
</script>
