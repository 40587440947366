import request from "@/utils/request";

// 光伏报警统计
export const alarmTotal = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/overview", { params });
};

// 报警记录导出
export const exportAlarmRecord = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/record/export", {
    params,
    responseType: "arraybuffer",
  });
};

// 报警总数柱状图
export const alarmTotalChart = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/record", { params });
};

// 等级报警占比
export const alarmLevel = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/alarmLevel", { params });
};

// 各类型设备报警占比
export const alarmType = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/alarmType", { params });
};

// 报警设备导出
export const exportAlarmDevice = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/alarmDevice/export", {
    params,
    responseType: "arraybuffer",
  });
};

// 报警设备数量
export const alarmDevice = (params: any): Promise<any> => {
  return request.get("/pv/alarm/statistics/alarmDevice", { params });
};
