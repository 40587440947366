import request from "@/utils/request";

// 视频监控列表
export const videomonitorList = (params: any): Promise<any> => {
  return request.get("/videomonitor/list", { params });
};

// 视频监控新增
export const videomonitorAdd = (data: any): Promise<any> => {
  return request.post("/videomonitor", data);
};

// 视频监控编辑
export const videomonitorEdit = (data: any): Promise<any> => {
  return request.put("/videomonitor", data);
};

// 视频监控删除
export const videomonitorDelete = (id: number): Promise<any> => {
  return request.delete(`/videomonitor/${id}`);
};

//监控场景列表
export const sceneList = (): Promise<any> => {
  return request.get("/videomonitor/sceneList");
};

//监控目标列表
export const targetList = (params: any): Promise<any> => {
  return request.get("/videomonitor/targetList", { params });
};

//排序调整
export const sortList = (data: any): Promise<any> => {
  return request.post("/videomonitor/sortList", data);
};

//添加其他项
export const other = (data: any): Promise<any> => {
  return request.post("/videomonitor/other", data);
};
