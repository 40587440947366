import { Directive } from "vue";
import { usePermission } from "@maxtropy/v-components";

export default {
  mounted(el, binding) {
    const { value } = binding;

    if (!usePermission(value)) {
      el.parentNode.removeChild(el);
    }
  },
} as Directive;
