export default () => {
  const time = new Date().getTime();

  const write = (query: any, timeout = 2000) => {
    if (new Date().getTime() - time <= timeout) {
      // 2秒内不响应，以避开刚打开时自动填充后的响应
      return;
    }

    const url = new URL(window.location.href);
    for (const key in query) {
      if (query[key] === null) {
        url.searchParams.delete(key);
        continue;
      }
      url.searchParams.set(key, query[key] as string);
    }

    history.replaceState("", "", url);
  };
  return {
    write,
  };
};
