import request from "./request";
import { dateRangeDec } from "@/utils/date";

export default (url: string, params: any = {}) => {
  if ("dateRange" in params) {
    const [startTime, endTime] = dateRangeDec(params.dateRange);
    delete params.dateRange;
    params.startTime = startTime;
    params.endTime = endTime;
  }
  if ("dateType" in params) {
    params.queryType = params.dateType;
    delete params.dateType;
  }
  request.get(url, { params, responseType: "arraybuffer" });
};
