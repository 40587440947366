<template>
  <div class="x-ellipsis-popover">
    <el-tooltip
      class="x-ellipsis-popover-tooltip"
      effect="dark"
      :content="content"
      :disabled="!isPopover"
      :placement="placement"
    >
      <div ref="conRef" class="content" :="$attrs">{{ content }}</div>
    </el-tooltip>
  </div>
</template>

<script setup lang="ts">
// 场景：随便一个文本，设定宽度，超时则省略号显示，此时hover时带tooltip
import { ref, nextTick, watchEffect } from "vue";

withDefaults(defineProps<{ content: string; placement?: string }>(), {
  content: "",
  placement: "bottom-start",
});

const conRef = ref<HTMLDivElement>();
const isPopover = ref(false);
watchEffect(() => {
  nextTick(() => {
    if (!conRef.value) return;
    const cWidth = conRef.value.clientWidth;
    const sWidth = conRef.value.scrollWidth;
    isPopover.value = sWidth > cWidth;
  });
});
</script>

<style lang="scss" scoped>
.x-ellipsis-popover {
  display: inline-flex;
  width: -webkit-fill-available;
  max-width: 100%;
  .content {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: -webkit-fill-available;
    max-width: 100%;
    cursor: default;
    user-select: none;
  }
}
</style>
