<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import numberParse from "@/utils/number";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});

const times = computed(() => props.data.map((d: any) => d.time));
const aValues = computed(() =>
  props.data.map((d: any) => numberParse(d.value)),
);

const tooltipFormatter = ([a]: Array<any>) => {
  if (null === a.value || undefined === a.value) return "";
  const time = dayjs(a.name).format("YYYY-MM-DD HH:mm");
  return `
    <div class="chart-tooltip">
      <div class="time">${time}</div>
      <div class="value-item">
        <label>电流</label><span style="color:#fed618">${numberParse(a.value)} A</span>
      </div>
    </div>
  `;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: times.value.length > 12,
    filterMode: "none",
  };
});

const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "A",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: aValues,
      type: "line",
      smooth: true,
      showSymbol: false,
      name: "电流",
      lineStyle: {
        color: "#fed618",
      },
      connectNulls: true,
    },
  ],
});
</script>
