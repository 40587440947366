<template>
  <div
    ref="refEchartBox"
    class="ref-echart-box flex-1"
    v-size-ob="handleSizeOb"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, nextTick, watch } from "vue";
import * as echarts from "echarts";
import theme from "@/echarts/theme.json";
import useWindowSize from "@/hooks/useWindowResize";

const props = defineProps({
  option: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  updateOptions: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(["click"]);

const refEchartBox = ref<HTMLDivElement>();
let chartIns: echarts.EChartsType;
const setupChart = () => {
  if (!refEchartBox.value) return;
  chartIns = echarts.init(refEchartBox.value, theme);
  chartIns.on("click", (e: Event) => {
    emit("click", e);
  });
  window.addEventListener("resize", onResize);
};

const onResize = () => {
  if (chartIns) {
    chartIns.resize();
  }
};
onUnmounted(() => {
  window.removeEventListener("resize", onResize);
});

watch(
  () => [props.option, props.updateOptions],
  () => {
    if (!chartIns) return;
    if (
      props.option.dataZoom &&
      typeof props.option.dataZoom === "object" &&
      !Array.isArray(props.option.dataZoom)
    ) {
      props.option.dataZoom.minValueSpan = 9;
    }
    chartIns.setOption(props.option, props.updateOptions);
  },
  { deep: true },
);

onMounted(() => {
  nextTick(() => {
    setupChart();
    if (chartIns) {
      chartIns.setOption(props.option, props.updateOptions);
    }
  });
});

// 光伏分析特有: 页面上提供class.chart-bottom-sticky，使唯一图表粘合环境下提供自应高底，底部为30px+20px
const { height } = useWindowSize();
watch(
  () => height.value,
  () => {
    nextTick(() => {
      const children = document.querySelectorAll(
        ".chart-bottom-sticky .ref-echart-box",
      );
      children.forEach(child => {
        if (child !== refEchartBox.value) return;
        setTimeout(() => {
          if (!refEchartBox.value) return;
          const target = refEchartBox.value.querySelector(
            "canvas",
          ) as HTMLCanvasElement;
          if (!target) return;
          const chartHeight =
            height.value - target.getBoundingClientRect().top - 50 + "px";
          target.style.height = chartHeight;
        }, 0);
      });
    });
  },
  {
    immediate: true,
  },
);

const handleSizeOb = () => {
  if (chartIns) {
    chartIns.resize();
  }
};
</script>
