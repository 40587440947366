<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase :option="option" :update-options="{ notMerge: true }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  total: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  unit: {
    type: String,
    default: "",
  },
  color: {
    type: Array,
    default: () => ["#2CC0FF", "#2CFFD9", "#5D7092", "#FF4D4F"],
  },
  isLegend: {
    type: Boolean,
    default: false,
  },
  chartCenter: {
    type: Array,
    default: () => ["50%", "50%"],
  },
  centerValueColor: {
    type: String,
    default: "#FAAD14",
  },
  tooltip: {
    type: Object,
    default: () => ({}),
  },
  radius: {
    type: Array,
    default: () => ["60%", "80%"],
  },
  minAngle: {
    type: Number,
    default: 0,
  },
  borderRadius: {
    type: Number,
    default: 0,
  },
});

const toNumber = (value: number) => {
  if (!value) return 0;
  if (typeof value === "string") {
    // @ts-ignore
    if (value.trim() === "") return 0;
    value = Number(value);
  }
  if (value % 1 === 0) return value;
  return value.toFixed(2);
};

const serieCfgs = {
  type: "pie",
  center: props.chartCenter,
  radius: props.radius,
  minAngle: props.minAngle,
  itemStyle: {
    borderRadius: 0,
    borderColor: "#232324",
    borderWidth: 2,
  },
  label: {
    show: true,
    position: "center",
    formatter: function () {
      return `{value|${props.total || ""}}\n{name|${props.title || ""}}`;
    },
    rich: {
      name: {
        fontSize: 14,
        color: "rgba(255,255,255,0.75)",
      },
      value: {
        fontSize: 29,
        color: props.centerValueColor,
      },
    },
  },
  labelLine: {
    show: false,
  },
};

const times = computed(() => {
  if (props.data.length === 0) return [];
  // @ts-ignore
  return props.data[0].data.map((d: any) => d.time);
});
const option = computed(() => ({
  color: props.color || ["#2CC0FF", "#2CFFD9", "#5D7092", "#FF4D4F"],
  legend: {
    show: props.isLegend,
    right: "20%",
    y: "center",
    orient: "vertical",
    formatter: function (name: string) {
      const items: any = props.data?.find((item: any): boolean => {
        return item.name === name;
      });
      return `{name|${name}}{rate|${items?.rate || 0}%}{value|${items.value}}`;
    },
    itemWidth: 8,
    itemHeight: 8,
    icon: "circle",
    itemGap: 20,
    textStyle: {
      rich: {
        name: {
          fontSize: 12,
          color: "rgba(255,255,255,0.65)",
          width: 90,
          padding: [0, 0, 0, 0],
        },
        rate: {
          fontSize: 14,
          color: "#ffffff",
          width: 80,
        },
        value: {
          fontSize: 14,
          color: "#4A90E2",
          width: 80,
        },
      },
    },
  },
  tooltip: {
    trigger: "item",
    confine: true,
    ...props.tooltip,
  },
  series: [
    {
      ...serieCfgs,
      data: props.data,
    },
  ],
}));
</script>
