import { onMounted, onUnmounted, ref } from "vue";

function useWindowResize() {
  const width = ref(0);
  const height = ref(0);

  function onResize() {
    // @ts-ignore
    width.value = window.innerWidth;
    // @ts-ignore
    height.value = window.innerHeight / (window.$zoom || 1);
  }

  onMounted(() => {
    window.addEventListener("resize", onResize);
    onResize();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", onResize);
  });

  return {
    width,
    height,
  };
}

export default useWindowResize;
