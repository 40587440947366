<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import numberParse from "@/utils/number";
import * as echarts from "echarts";

const props = defineProps({
  data: {
    type: Object,
    default: () => ({
      ab: [],
      bc: [],
      ca: [],
    }),
  },
});

const typeConfigs = {
  ab: {
    title: "AB线电压",
    color: "rgba(254, 214, 24, 0.7)",
    hColor: "rgb(254, 214, 24)",
  },
  bc: { title: "BC线电压", color: "rgba(48, 194, 91, 0.7)", hColor: "#30c25b" },
  ca: { title: "CA线电压", color: "rgba(255, 75, 74, 0.7)", hColor: "#ff4b4a" },
};

const times = computed(() => props.data.ab.map((d: any) => d.time));
const abValues = computed(() =>
  props.data.ab.map((d: any) => numberParse(d.value)),
);
const bcValues = computed(() =>
  props.data.bc.map((d: any) => numberParse(d.value)),
);
const caValues = computed(() =>
  props.data.ca.map((d: any) => numberParse(d.value)),
);

const tooltipFormatter = ([ab, bc, ca]: Array<any>) => {
  const time = dayjs(ab.name).format("YYYY-MM-DD HH:mm");

  const lists = [];
  if (ab && null !== ab.value && undefined !== ab.value) {
    lists.push({
      title: typeConfigs.ab.title,
      value: numberParse(ab.value),
      color: typeConfigs.ab.color,
    });
  }
  if (bc && null !== bc.value && undefined !== bc.value) {
    lists.push({
      title: typeConfigs.bc.title,
      value: numberParse(bc.value),
      color: typeConfigs.bc.color,
    });
  }
  if (ca && null !== ca.value && undefined !== ca.value) {
    lists.push({
      title: typeConfigs.ca.title,
      value: numberParse(ca.value),
      color: typeConfigs.ca.color,
    });
  }
  const listHtml = lists
    .map(
      d => `
    <div class="value-item">
      <label>${d.title}</label><span style="color:${d.color}">${d.value} V</span>
    </div>
  `,
    )
    .join("");
  if ("" === listHtml) return "";
  return `
    <div class="chart-tooltip">
      <div class="time">${time}</div>
      ${listHtml}
    </div>
  `;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: times.value.length > 12,
    filterMode: "none",
  };
});

const createSerie = (data: [], type: string) => {
  return {
    data: data,
    type: "line",
    smooth: true,
    showSymbol: false,
    // @ts-ignore
    name: typeConfigs[type].title,
    lineStyle: {
      // @ts-ignore
      color: typeConfigs[type].color,
    },
  };
};

const serieItemCfg = {
  type: "line",
  smooth: true,
  symbol: "none",
  connectNulls: true,
  legendHoverLink: true,
};

const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
    selectedMode: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "V",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: abValues,
      name: typeConfigs.ab.title,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: typeConfigs.ab.color },
          { offset: 1, color: typeConfigs.ab.hColor },
        ]),
      },
      ...serieItemCfg,
    },
    {
      data: bcValues,
      name: typeConfigs.bc.title,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: typeConfigs.bc.color },
          { offset: 1, color: typeConfigs.bc.hColor },
        ]),
      },
      ...serieItemCfg,
    },
    {
      data: caValues,
      name: typeConfigs.ca.title,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: typeConfigs.ca.color },
          { offset: 1, color: typeConfigs.ca.hColor },
        ]),
      },
      ...serieItemCfg,
    },
  ],
});
</script>
