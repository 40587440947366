<template>
  <div class="flex-1 flex-column" style="position: relative">
    <ChartBase :option="option" :update-options="{ notMerge: true }" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import merge from "merge";
import * as echarts from "echarts";
import { histogram } from "echarts-stat";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  unit: {
    type: String,
    default: "",
  },
  color: {
    type: Array,
    default: () => [],
  },
  yAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  xAxisOptions: {
    type: Object,
    default: () => ({}),
  },
  isLegend: {
    type: Boolean,
    default: false,
  },
  barWidth: {
    type: Number,
    default: 30,
  },
});

const toNumber = (value: number) => {
  if (!value) return 0;
  if (typeof value === "string") {
    // @ts-ignore
    if (value.trim() === "") return 0;
    value = Number(value);
  }
  if (value % 1 === 0) return value;
  return value.toFixed(2);
};

// See https://github.com/ecomfe/echarts-stat
const bins = histogram(props.data || []);
// 柱子间间隔的刻度
let interval = 0;

let min = Infinity;
let max = -Infinity;

// 自定义渲染效果
const renderItem = (params: any, api: any) => {
  // 这个根据自己的需求适当调节
  let yValue = api.value(2);
  let start = api.coord([api.value(0), yValue]);
  let size = api.size([api.value(1) - api.value(0), yValue]);
  let style = api.style();

  return {
    // 矩形及配置
    type: "rect",
    shape: {
      x: start[0] + 1,
      y: start[1],
      width: size[0] - 2,
      height: size[1],
    },
    style: style,
  };
};
const resultData = echarts.util.map(
  bins.data,
  function (item: any, index: number) {
    // 左刻度
    let x0 = bins.bins[index].x0;
    // 右刻度
    let x1 = bins.bins[index].x1;
    interval = x1 - x0;
    // 获得数据集中最值
    min = Math.min(min, x0);
    max = Math.max(max, x1);
    // item[0]代表刻度的中间值，item[1]代表出现的次数
    return [x0, x1, item[1]];
  },
);
const option = computed(() => ({
  grid: {
    x: 40,
    y: 60,
    right: props.xAxisOptions ? 100 : 10,
    bottom: 40,
  },
  legend: {
    show: props.isLegend,
    right: 0,
    itemWidth: 16,
    itemHeight: 7,
  },
  tooltip: {
    confine: true,
    formatter: function (item: any) {
      return `电流离散率范围在${parseFloat(item.data[0]).toFixed(2)}%~${parseFloat(item.data[1]).toFixed(2)}% <br> 个数为${item.data[2]}`;
    },
  },
  color: props.color,
  xAxis: {
    type: "value",
    min: min,
    max: max,
    interval: interval,
    axisLabel: {
      lineHeight: 20,
      color: "#FFFFFF",
      formatter: function (name: any) {
        return parseFloat(name).toFixed(2) + "%";
      },
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: "rgba(255, 255, 255, 0.3)",
      },
    },
    ...props.xAxisOptions,
  },
  yAxis: {
    type: "value",
    name: props.unit,
    axisLabel: {
      lineHeight: 20,
      color: "#FFFFFF",
    },
    minInterval: 1,
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.3)",
      },
    },
    ...props.yAxisOptions,
  },
  series: [
    {
      name: "离散率个数",
      type: "custom",
      renderItem: renderItem,
      label: {
        show: false,
        position: "insideTop",
      },
      encode: {
        // 表示将data中的data[0]和data[1]映射到x轴
        x: [0, 1],
        // 表示将data中的data[2]映射到y轴
        y: 2,
        // 表示将data中的data[2]映射到tooltip
        tooltip: 2,
        // 表示将data中的data[2]映射到label
        label: 2,
      },
      data: resultData,
    },
  ],
}));
</script>
