import request from "@/utils/request";

// 离散率设置组
export const settingList = (params: any): Promise<any> => {
  return request.get("/performanceanalysis/string/discrete/setting/list", {
    params,
  });
};

// 添加离散率分析设置
export const discreteAdd = (data: any): Promise<any> => {
  return request.post("/performanceanalysis/string/discrete/setting", data);
};

// 编辑离散率分析设置
export const discretEdit = (data: any): Promise<any> => {
  return request.put("/performanceanalysis/string/discrete/setting", data);
};

//编辑离散率分析详情
export const discretDetail = (id: number): Promise<any> => {
  return request.get(
    `/performanceanalysis/string/discrete/setting/detail/${id}`,
  );
};

//下载模板
export const download = (): Promise<any> => {
  return request.get(
    `/performanceanalysis/string/discrete/setting/template/download`,
  );
};

//导入
export const importe = (data: any): Promise<any> => {
  return request.post(
    `/performanceanalysis/string/discrete/setting/import`,
    data,
  );
};

//光伏站综合离散率列表
export const dispersionList = (): Promise<any> => {
  return request.get(`/performanceanalysis/station/dispersion/list`);
};

//直方图
export const inverterCount = (): Promise<any> => {
  return request.get(`/performanceanalysis/station/dispersion/inverter/count`);
};

//逆变器离散率
export const inverterList = (): Promise<any> => {
  return request.get(`/performanceanalysis/inverter/dispersion/list`);
};

// 组串电流信息
export const currentList = (id: number): Promise<any> => {
  return request.get(`/performanceanalysis/string/current/list`);
};

//获取未配置的光伏站列表
export const getStationList = (): Promise<any> => {
  return request.get(`/performanceanalysis/string/stationList`);
};

//获取未配置的片区列表
export const getAreaList = (stationId: any): Promise<any> => {
  return request.get(`/performanceanalysis/string/${stationId}/areaList`);
};

//获取光伏站和片区的列表
export const getStationAreaList = (): Promise<any> => {
  return request.get(`/performanceanalysis/string/stationAndAreaList`);
};
