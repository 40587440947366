<template>
  <div class="flex-1 flex-column">
    <div>
      <SummaryBlock
        title="期间可用小时数累计"
        :value="totalDesc"
        unit=""
        style="margin-bottom: 25px"
      />
    </div>
    <div ref="refPos"></div>
    <ChartBase :option="option" class="flex-1 flex-column" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import { hoursFormat } from "@/utils/unitFormat";
import * as echarts from "echarts";

const props = defineProps({
  mode: {
    type: String,
    required: true,
    default: "day",
  },
  data: {
    type: Array,
    default: [],
  },
});

const totalDesc = computed(() => {
  if (props.data.length === 0) return 0;
  const total = props.data.map((d: any) => d.value).reduce((a, b) => a + b);
  return hoursFormat(total);
});
const times = computed(() => props.data.map((d: any) => d.time));
const values = computed(() => props.data.map((d: any) => d.value));

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: props.data.length > 12,
  };
});

const tooltipFormatter = ([{ name, value }]: any) => {
  if (undefined === value || null === value) return "";
  const date = dayjs(name);
  const timesDesc = hoursFormat(value);
  if (props.mode === "hour") {
    return `<div style="margin-bottom:8px">${date.format(
      "YYYY-MM-DD HH:mm",
    )}</div><span style="display:inline-block;margin-right:10px">可用小时数</span> <span style="color:#4A90E2">${timesDesc}</span>`;
  }
  if (props.mode === "day") {
    return `<div style="margin-bottom:8px">${date.format(
      "YYYY-MM-DD",
    )}</div><span style="display:inline-block;margin-right:10px">可用小时数</span> <span style="color:#4A90E2">${timesDesc}</span>`;
  }
  if (props.mode === "month") {
    return `<div style="margin-bottom:8px">${date.format(
      "YYYY年MM月",
    )}</div><span style="display:inline-block;margin-right:10px">可用小时数</span> <span style="color:#4A90E2">${timesDesc}</span>`;
  }
};

function xAxisLableFormatter(name: any) {
  const date = dayjs(name);
  if (props.mode === "day") {
    return `${date.format("MM-DD")}`;
  }
  if (props.mode === "month") {
    return `${date.format("MM月")}\n${date.format("YY年")}`;
  }
}

const option = computed(() => ({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
    selectedMode: false,
    icon: "path://M0,0H16V7H0Z",
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: tooltipFormatter,
  },
  dataZoom: dataZoom.value,
  xAxis: {
    type: "category",
    data: times.value,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      type: "bar",
      name: "可用小时数",
      data: values.value,
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#00def2" },
          { offset: 1, color: "#0092cb" },
        ]),
      },
      barMaxWidth: "24px",
      barMinWidth: "2px",
    },
  ],
}));
</script>
