<template>
  <ChartKw :data="data" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { fetchKw } from "@/api/inverter";
import { TimeValueResult } from "@/api/@types";
import { ChartProps } from "./common";

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateRange: ["", ""],
    dateType: "fifteen_minute",
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>([]);
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (!query.deviceId || query.dateRange.length !== 2) {
      data.value = [];
      return;
    }
    loading.value = true;
    fetchKw(query.deviceId, query.dateRange, query.dateType).then(list => {
      data.value = list;
      loading.value = false;
    });
  },
  { immediate: true },
);
</script>
