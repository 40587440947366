import request from "@/utils/request";
import {
  Inverter,
  DateRangeTypeHDM,
  TimeValueResult,
  LineVoltageResult,
  VoltagePhaseResult,
  ElectricityResult,
} from "./@types";
import { dateRangeDec } from "@/utils/date";

export const list = (
  stationId: number,
  areaId: number,
  combinerBoxId: number,
): Promise<Inverter[]> => {
  const params = { stationId, areaId, combinerBoxId };
  return request.get("/common/list/inverter", { params });
};

// 发电量
export const fetchPower = (
  deviceId: string,
  dateRange: [string, string],
  queryType: DateRangeTypeHDM,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/power", { params });
};

// 发电功率
export const fetchKw = (
  deviceId: string,
  dateRange: [string, string],
  dateType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType: dateType };
  return request.get("/data/inverter/kw", { params });
};

// 效率
export const fetchEfficiency = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/efficiency", { params });
};

// 线电压
export const fetchLineVoltage = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<LineVoltageResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/voltage/line", { params });
};

// 相电压
export const fetchPhaseVoltage = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<VoltagePhaseResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/voltage/phase", { params });
};

// 电流
export const fetchElectricity = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<ElectricityResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/electricity", { params });
};

// 功率因数
export const fetchKwfactor = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/kwfactor", { params });
};

// 温度
export const fetchTemperature = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/temperature", { params });
};

// DC输入总功率
export const fetchDckw = (
  deviceId: string,
  dateRange: [string, string],
  queryType: string,
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime, queryType };
  return request.get("/data/inverter/dckw", { params });
};
