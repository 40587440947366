<template>
  <ChartActivepower :data="data" v-loading="loading" />
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { fetchActivepower } from "@/api/HVCabinet";
import { TimeValueResult } from "@/api/@types";

type QueryType = {
  deviceId: string;
  dateRange: [string, string];
};

interface ChartProps {
  query: QueryType;
}

const props = withDefaults(defineProps<ChartProps>(), {
  query: () => ({
    deviceId: "0",
    dateRange: ["", ""],
  }),
});

const loading = ref(false);

// 加载数据
const data = ref<TimeValueResult[]>([]);
watch(
  () => props.query,
  () => {
    const query = props.query;
    if (!query.deviceId || query.dateRange.length !== 2) {
      data.value = [];
      return;
    }
    loading.value = true;
    fetchActivepower(query.deviceId, query.dateRange)
      .then(list => {
        data.value = list;
        loading.value = false;
      })
      .catch(() => {
        loading.value = false;
      });
  },
  { immediate: true },
);
</script>
