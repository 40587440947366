<template>
  <div class="flex-1 flex-column" style="position: relative; height: 100%">
    <div>
      <SummaryBlock
        title="峰值功率"
        :value="maxValue.value"
        :unit="maxValue.unit"
        style="margin-bottom: 25px"
      />
    </div>
    <ChartBase :option="option" class="flex-1 flex-column" />
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import { kwFormat } from "@/utils/unitFormat";
import numberParse from "@/utils/number";
import * as echarts from "echarts";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});

// @ts-ignore
const maxValue = computed<{ value: string; unit: string }>(() => {
  if (props.data.length === 0) return 0;
  const values = props.data.map((d: any) => d.value);
  const maxValue: number = Math.max.apply(null, values);
  // @ts-ignore
  return kwFormat(maxValue);
});

const times = computed(() => props.data.map((d: any) => d.time));
const values = computed(() => props.data.map((d: any) => numberParse(d.value)));

const tooltipFormatter = ([d]: Array<any>) => {
  if (null === d.value || undefined === d.value) return "";
  const time = dayjs(d.name).format("YYYY-MM-DD HH:mm");
  return `<div style="margin-bottom:8px">${time}</div><span style="display:inline-block;margin-right:35px">DC输入总功率</span> <span style="color:#4A90E2">${numberParse(
    d.value,
  )} kW</span>`;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    show: props.data.length > 12,
    filterMode: "none",
  };
});
const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "line",
      snap: true,
    },
    formatter: tooltipFormatter,
  },
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "kW",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: values,
      type: "line",
      smooth: true,
      symbol: "none",
      name: "DC输入总功率",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#2cc4f9" },
          { offset: 1, color: "#0267ad" },
        ]),
      },
      areaStyle: {
        opacity: 0.8,
        color: "rgba(74, 144, 226, 0.16)",
      },
      connectNulls: true,
    },
  ],
});
</script>
