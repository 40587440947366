import request from "@/utils/request";

// 逆变器绩效分析列表
export const inverterList = (type: number, params: any): Promise<any> => {
  return request.get(`/performanceanalysis/inverter/${type}/list`, { params });
};

// 逆变器绩效分析导出
export const inverterExport = (type: number, params: any): Promise<any> => {
  return request.get(`/performanceanalysis/inverter/${type}/export`, {
    params,
  });
};
