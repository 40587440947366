// 最简单的防抖函数
const store = {};

export default (name: string, callback: Function, wait = 100) => {
  // @ts-ignore
  if (store[name]) {
    // @ts-ignore
    clearTimeout(store[name]);
  }
  // @ts-ignore
  store[name] = setTimeout(() => {
    callback();
    // @ts-ignore
    delete store[name];
  }, wait);
};
