<template>
  <ChartBase :option="option" />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import dayjs from "dayjs";
import np from "@/utils/number";
import * as echarts from "echarts";

const props = defineProps({
  data: {
    type: Array,
    default: [],
  },
});

const times = computed(() => props.data.map((d: any) => d.time));
const values = computed(() => {
  if (import.meta.env.MODE === "development") {
    return props.data.map((d: any) => {
      return Math.floor(Math.random() * (120 - 20 + 1)) + 20;
    });
  }
  return props.data.map((d: any) => d.value);
});

const tooltipFormatter = ([d]: Array<any>) => {
  if (undefined === d.value || null === d.value) return "";
  const time = dayjs(d.name).format("YYYY-MM-DD HH:mm");
  return `<div style="margin-bottom:8px">${time}</div><span style="display:inline-block;margin-right:35px">温度</span> <span style="color:#4A90E2">${np(
    d.value,
  )} ℃</span>`;
};

const xAxisLableFormatter = (time: string) => {
  const date = dayjs(time);
  const shortDate = date.format("MM-DD");
  const shortTime = date.format("HH:mm");

  return `${shortTime}\n${shortDate}`;
};

const dataZoom = computed(() => {
  return {
    slider: {
      type: "slider",
    },
    show: props.data.length > 12,
    filterMode: "none",
  };
});
const option = ref({
  grid: {
    x: 60,
    y: 60,
    right: 10,
    bottom: 100,
  },
  legend: {
    right: 0,
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "line",
      snap: true,
    },
    formatter: tooltipFormatter,
  },
  visualMap: [
    {
      type: "continuous",
      show: false,
      min: 80,
      max: 90,
      inRange: {
        color: ["#819f6f", "#de5850"],
      },
    },
  ],
  dataZoom,
  xAxis: {
    type: "category",
    data: times,
    axisTick: {
      show: false,
    },
    axisLabel: {
      formatter: xAxisLableFormatter,
      lineHeight: 20,
      align: "center",
    },
  },
  yAxis: {
    type: "value",
    name: "℃",
    splitLine: {
      lineStyle: {
        color: "rgba(255, 255, 255, 0.30)",
      },
    },
  },
  series: [
    {
      data: values,
      type: "line",
      smooth: true,
      symbol: "none",
      name: "温度",
      itemStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "#ce5850" }, // #d9363e
          { offset: 1, color: "#819f6f" }, // #2bd784
        ]),
      },
      connectNulls: true,
    },
  ],
});
</script>
