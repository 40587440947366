<!--
高压设备 箱变专用选择，因为较出入线和SVG多了一个并网点查询参数
-->
<template>
  <mx-select
    v-model="currentValue"
    :disabled="!loaded"
    placeholder="请选择"
    fit-input-width
    style="width: 158px"
  >
    <mx-option
      v-for="item in options"
      :key="item.deviceId"
      :label="item.name"
      :value="item.deviceId"
    />
  </mx-select>
</template>

<script setup lang="ts">
import { ref, watch, computed } from "vue";
import { fetchTransformers as fetchList } from "@/api/gridPoint";

const props = defineProps({
  stationId: {
    type: Number,
    default: 0,
    require: true,
  },
  gridPointId: {
    type: Number,
    default: 0,
    require: true,
  },
  modelValue: {
    type: String,
    default: null,
  },
});
const emit = defineEmits(["update:modelValue"]);
const currentValue = computed<string>({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});

const options = ref<any[]>([]);

const loaded = ref(false);
watch(
  () => [props.stationId, props.gridPointId],
  () => {
    if (!props.stationId || !props.gridPointId) return;
    loaded.value = false;
    fetchList(props.stationId, props.gridPointId).then(list => {
      options.value = list;
      emit("update:modelValue", list.length > 0 ? list[0]["deviceId"] : null);
      loaded.value = true;
    });
  },
  { immediate: true },
);
</script>
