<template>
  <div
    class="tree-side-page flex justify-between"
    :class="{ 'is-collapsed': isSideCollapsed }"
  >
    <div class="tree-side">
      <div class="side-tree-wrap">
        <slot name="side" />
      </div>
      <Hamburg v-model="isSideCollapsed" />
    </div>
    <div v-loading="loading" class="tree-main">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import Hamburg from "./hamburg.vue";

defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});

const isSideCollapsed = ref(false);
</script>

<style lang="scss" scoped>
.tree-side-page {
  user-select: none;
  height: 100%;
  overflow: hidden;
  .tree-side {
    position: relative;
    width: 260px;
    min-height: 100%;
    background: #232324;
    border-radius: 2px;
    box-sizing: border-box;
    padding: 15px 0;
  }
  .tree-main {
    flex: 1;
    margin-left: 10px;
    box-sizing: border-box;
    background: #232324;
    overflow: auto;
  }
  &.is-collapsed {
    .tree-side {
      width: 0;
      .side-tree-wrap {
        visibility: hidden;
      }
    }
    :deep(.tree-side-page_hamburg) {
      left: 1px !important;
      z-index: 99;
      // background: rgba(255, 255, 255, 0.1);
      svg {
        transform: scale(-1, 1);
      }
    }
    .tree-main {
      margin-left: 0;
    }
  }

  .tree-node-item {
    .tree-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
</style>
