//获取树的结构 通过id
export const getNode = (data: any, id: number) => {
  let result = null; // 运行结果
  function getTreeItem(data: any, id: number) {
    data.map((item: any) => {
      if (item.id == id) {
        result = item; // 结果赋值
      } else {
        if (item.children) {
          getTreeItem(item.children, id);
        }
      }
    });
  }
  getTreeItem(data, id);
  return result;
};
