import request from "@/utils/request";

// 组织结构和光伏站树
export const treeselect = (params?: any): Promise<any> => {
  return request.get("/pv/diagnosis/treeselect", { params });
};

// 光伏诊断分析-公共
export const commonInfo = (stationId: number, params: any): Promise<any> => {
  return request.get(`/pv/diagnosis/commonInfo/${stationId}`, { params });
};

// 非经济用能分析结果
export const neeAnalysisList = (
  stationId: number,
  params: any,
): Promise<any> => {
  return request.get(`/pv/diagnosis/neeAnalysisList/${stationId}`, { params });
};

// 低效逆变器
export const inverterDispersionList = (
  stationId: number,
  params: any,
): Promise<any> => {
  return request.get(`/pv/diagnosis/inverterDispersionList/${stationId}`, {
    params,
  });
};
// 组串处理不均统计
export const stringStatistic = (
  stationId: number,
  params: any,
): Promise<any> => {
  return request.get(`/pv/diagnosis/stringStatistic/${stationId}`, { params });
};

// 光伏站综合离散率趋势图
export const stationDispersionCharts = (
  stationId: number,
  params: any,
): Promise<any> => {
  return request.get(`/pv/diagnosis/stationDispersionCharts/${stationId}`, {
    params,
  });
};
