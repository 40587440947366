import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import NormalLayout from "@/components/layout/normal-layout.vue";
import { getBase } from "@maxtropy/v-components";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    component: NormalLayout,
    children: [
      // 发电数据分析
      {
        path: "/overview",
        name: "overview",
        component: () => import("@/views/overview/overview.vue"),
        meta: {
          title: "总览",
          scroll: false,
          permission: "p-GeneralOverview",
          screenSize: [1366, 900],
        },
      },
      {
        path: "/analysis/station",
        name: "analyse_station",
        component: () => import("@/views/analysis-station/station.vue"),
        meta: {
          title: "光伏站分析",
          permission: "p-pvStationAnalysis",
          scroll: false,
        },
      },
      {
        path: "/analysis/area",
        name: "analyse_area",
        component: () => import("@/views/analysis-area/area.vue"),
        meta: {
          title: "片区分析",
          permission: "p-pvAreaAnalysis",
        },
      },
      {
        path: "/analysis/inverter",
        name: "analyse_inverter",
        component: () => import("@/views/analysis-inverter/inverter.vue"),
        meta: {
          title: "逆变器分析",
          permission: "p-inverterAnalysis",
        },
      },
      {
        path: "/analysis/series",
        name: "analyse_series",
        component: () => import("@/views/analysis-serial/serial.vue"),
        meta: {
          title: "组串分析",
          permission: "p-pvStringAnalysis",
          scroll: false,
        },
      },
      {
        path: "/station-analysis",
        name: "station-analysis",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/station-analysis/index.vue"
          ),
        meta: {
          title: "站点数据分析",
          scroll: false,
          permission: "p-pvOperatingCurveData",
          bodyBg: false,
        },
      },
      // 高压设备
      {
        path: "/analysis-hvd/in",
        name: "hve-in",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/analysis-hvc/hvc.vue"),
        meta: {
          title: "光伏进线分析",
          permission: "p-pvInletAnalysis",
        },
        props: { type: 2 },
      },
      {
        path: "/analysis-hvd/out",
        name: "hve-out",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/analysis-hvc/hvc.vue"),
        meta: {
          title: "并网出线分析",
          permission: "p-gridConnectionOutletAnalysis",
        },
        props: { type: 1 },
      },
      {
        path: "/analysis-hvd/svg",
        name: "hvd-svg",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/analysis-hvc/hvc.vue"),
        meta: {
          title: "SVG分析",
          permission: "p-SVGAnalysis",
        },
        props: { type: 0 },
      },
      {
        path: "/analysis-hvd/transformer",
        name: "hvd-transformer",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/analysis-hvc/hvc.vue"),
        meta: {
          title: "箱变分析",
          permission: "p-transformerAnalysis",
        },
        props: { type: 3 },
      },
      {
        path: "/hvc-analysis",
        name: "hvc-analysis",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/hvc-analysis/index.vue"
          ),
        meta: {
          title: "高压设备分析",
          permission: "p-pvHighVoltageEquipmentCurves",
          scroll: false,
          bodyBg: false,
        },
      },
      // 对比分析
      {
        path: "/compares/station",
        name: "compares-station",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/compares-station/station.vue"
          ),
        meta: {
          title: "光伏站对比分析",
          permission: "p-pvStationComparativeAnalysis",
        },
      },
      {
        path: "/compares/inverter",
        name: "compares-inverter",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/compares-inverter/inverter.vue"
          ),
        meta: {
          title: "逆变器对比分析",
          permission: "p-inverterComparativeAnalysis",
        },
      },
      {
        path: "/compares/serial",
        name: "compares-serial",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/compares-serial/serial.vue"
          ),
        meta: {
          title: "组串对比分析",
          permission: "p-pvStringComparativeAnalysis",
        },
      },
      // 光伏站数据监控
      {
        path: "/monitoring/powerside",
        name: "monitoring-powerside",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/powerside/powerside.vue"
          ),
        meta: {
          title: "发电侧数据监控",
          permission: "p-generationSideMonitoring",
        },
      },
      {
        path: "/video-monitor",
        name: "video-monitor",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/video-monitor/video-monitor.vue"
          ),
        meta: {
          title: "视频监控",
          scroll: true,
          permission: "p-pvVedioMonitor",
        },
      },
      {
        path: "/performance/station",
        name: "performance-station",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/station/index.vue"
          ),
        meta: {
          title: "光伏站绩效",
          scroll: true,
          permission: "p-pvStationPerformance",
        },
      },
      {
        path: "/performance/system",
        name: "performance-system",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/system/index.vue"
          ),
        meta: {
          title: "系统绩效分析",
          scroll: true,
          permission: "p-systemEfficiencyAnalysis",
        },
      },
      {
        path: "/performance/inverter",
        name: "performance-inverter",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/inverter/index.vue"
          ),
        meta: {
          title: "逆变器绩效",
          scroll: true,
          permission: "p-pvInverterPerformance",
        },
      },
      {
        path: "/performance/assessments",
        name: "performance-assessments",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/assessments/index.vue"
          ),
        meta: {
          title: "光伏健康评估",
          scroll: true,
          permission: "p-pvStationHealthAccessment",
        },
      },
      {
        path: "/performance/dispersion-setting",
        name: "performance-dispersion-setting",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/dispersion-setting/index.vue"
          ),
        meta: {
          title: "离散度分析设置",
          scroll: true,
          permission: "p-currentDispersionRateConfig",
        },
      },
      {
        path: "/performance/dispersion-serial",
        name: "performance-dispersion-serial",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/performance/dispersion-serial/index.vue"
          ),
        meta: {
          title: "组串离散率分析",
          scroll: true,
          permission: "p-pvStringDispersionRateConfig",
        },
      },
      {
        path: "/string-dispersion",
        name: "string-dispersion",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/string-dispersion/index.vue"
          ),
        meta: {
          title: "组串离散率分析",
          scroll: false,
          permission: "p-pvDispersionRateAnalysisV2",
          bodyBg: false,
        },
      },
      {
        path: "/actual-time-board-v2",
        name: "actual-time-board-v2",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/actual-time-board-v2/actual-time-board-v2.vue"
          ),
        meta: {
          title: "实时数据看板v2",
          scroll: false,
          permission: "p-inverterRealTimeSignageV2",
          bodyBg: false,
        },
      },
      {
        path: "/actual-time-board",
        name: "actual-time-board",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/actual-time-board/actual-time-board.vue"
          ),
        meta: {
          title: "实时数据看板",
          scroll: false,
          permission: "p-pvRealTimeDataOverview",
          bodyBg: false,
        },
      },
      //非经济性用能
      {
        path: "/neec/statistics",
        name: "neec-statistic",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/NEEC/Statistic.vue"),
        meta: {
          title: "非经济性能源统计",
          permission: "p-pvNonEconomicEnergyUsed",
        },
      },
      //光伏报警统计
      {
        path: "/alarm/statistics",
        name: "alarm-statistic",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/alarm/Statistic.vue"),
        meta: {
          title: "光伏报警统计",
          permission: "p-pvAlarmStatistics",
          scroll: true,
        },
      },
      //站点级运营概览
      {
        path: "/operate-overview",
        name: "operate-overview",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/operate-overview/index.vue"
          ),
        meta: {
          title: "站点级运营概览",
          permission: "p-pvStationOperationsOverview",
        },
      },
      //光伏收益统计分析
      {
        path: "/income-analysis",
        name: "income-analysis",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "@/views/income-analysis/index.vue"
          ),
        meta: {
          title: "光伏收益统计分析",
          permission: "p-pvEarningsStatisticalAnalysis",
        },
      },
      //光伏诊断分析
      {
        path: "/diagnosis",
        name: "diagnosis",
        component: () => import("@/views/diagnosis/index.vue"),
        meta: {
          title: "光伏诊断分析",
          scroll: false,
          permission: "p-pv-analysis-and-diagnosis",
        },
      },

      {
        path: "/",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/home.vue"),
        meta: {
          title: "首页",
          hidden: true,
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "not-found",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/not-found.vue"),
        meta: {
          hidden: true,
        },
      },
    ],
  },
  {
    path: "/cer",
    name: "CER",
    component: () => import("@/views/CER/overview.vue"),
    meta: {
      title: "碳减排总览",
      scroll: false,
      screenSize: [1920, 1080],
      permission: "p-carbon-emission-reduction-screen",
    },
  },
  {
    path: "/cer-html",
    name: "CERHTML",
    component: () => import("@/views/CER-HTML/overview.vue"),
    meta: {
      title: "碳减排总览(静态)",
      scroll: false,
      screenSize: [1920, 1080],
      permission: "p-carbon-emission-reduction-screen",
    },
  },
];

export const devRoutes: Array<RouteRecordRaw> = [
  {
    path: "/video-monitor",
    name: "video-monitor",
    component: () =>
      import(
        /* webpackChunkName: "home" */ "@/views/video-monitor/video-monitor.vue"
      ),
    meta: {
      title: "视频监控",
    },
  },
];

const router = createRouter({
  history: createWebHistory(getBase() || import.meta.env.BASE_URL),
  routes: routes,
});

export default router;
