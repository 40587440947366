import request from "@/utils/request";

/*
 *   @function 大屏左侧数据(碳资产 + 来源占比 + 环保贡献统计)
 *   @description 大屏左侧数据(碳资产 + 来源占比 + 环保贡献统计)
 *     url /bigscreen/carbonAssets
 *   @author zheng
 *   @version 0.0.1
 */
export const carbonAssets = (): Promise<any> => {
  return request.get("/bigscreen/carbonAssets");
};

/*
 *   @function 光伏站与充电站今日减排量列表
 *   @description 光伏站与充电站j今日减排量列表
 *     url /bigscreen/pvChargeList
 *   @param {number} arg1 被乘数（接受小数和整数）
 *   @param {number} arg1 乘数（接受小数和整数）
 *   @parameter fix: 乘积保留几位（接受正负整数以及0）
 *   @returns {number} 计算结果
 *   @author zheng
 *   @version 0.0.1
 *   @example
 *    let  res = accMul(2.01,1000,2)
 *    console.log(res) // 2010.00
 */
export const pvChargeList = () => {
  return request.get("/bigscreen/pvChargeList");
};

/*
 *   @function 近一月减排(最近30天)
 *   @description 光伏站与充电站j今日减排量列表
 *     url /bigscreen/monthReduction
 *   @author zheng
 *   @version 0.0.1
 */
export const monthReduction = () => {
  return request.get("/bigscreen/monthReduction");
};

/*
 *   @function 近一年减排(最近12个月)
 *   @description 光伏站与充电站j今日减排量列表
 *     url /bigscreen/yearReduction
 *   @author zheng
 *   @version 0.0.1
 */
export const yearReduction = () => {
  return request.get("/bigscreen/yearReduction");
};

/*
 *   @function 光伏站碳减排详情
 *   @description 光伏站碳减排详情
 *     url /bigscreen/pvDetails/{id}
 *   @param {id} arg1 光伏id
 *   @returns {Object} 
 *   {
        "yearReduction": "string",
        "totalReduction": "string",
        "capacity": "string",
        "monthCoal": "string",
        "monthTree": "string",
        "yearCoal": "string",
        "yearTree": "string",
        "totalCoal": "string",
        "totalTree": "string"
    }
 *   @author zheng
 *   @version 0.0.1
 */
export const pvDetails = (id: number) => {
  return request.get(`/bigscreen/pvDetails/${id}`);
};

/*
     *   @function 充电站碳减排详情
     *   @description 光伏站碳减排详情
     *     url /bigscreen/chargeDetails/{id}
     *   @param {id} arg1 光伏id
     *   @returns {Object} 
     *   {
            "yearReduction": "string",
            "totalReduction": "string",
            "capacity": "string",
            "monthCoal": "string",
            "monthTree": "string",
            "yearCoal": "string",
            "yearTree": "string",
            "totalCoal": "string",
            "totalTree": "string"
        }
     *   @author zheng
     *   @version 0.0.1
     */
export const chargeDetails = (id: number) => {
  return request.get(`/bigscreen/chargeDetails/${id}`);
};

/*
 *   @function 站点碳减排趋势
 *   @description 光伏站碳减排详情
 *     url /bigscreen/trend/{type}/{id}
 *   @param {id} arg1 光伏id
 *   @returns {Array} [{
        "time": "string",
        "value": "string"
    }] 
 *   @author zheng
 *   @version 0.0.1
 *   @example 
 *   let  res = accMul(2.01,1000,2) 
 *    console.log(res) // 2010.00
 */
export const findTrend = (type: number, id: number, params: any) => {
  return request.get(`/bigscreen/trend/${type}/${id}`, { params });
};
