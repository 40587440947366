import request from "@/utils/request";
import {
  HVCabinet,
  TimeValueResult,
  LineVoltageResult,
  VoltagePhaseResult,
  ElectricityResult,
} from "./@types";
import { dateRangeDec } from "@/utils/date";

/**
 * @name 查询类型
 */
type HVCabinetType = 0 | 1 | 2 | 3;

/**
 * @name 列表
 * @param {number} stationId 站点id
 * @param {number} type 查询类型， 0=SVG 1=并网出线柜 2=光伏进线柜
 */
export const list = (
  stationId: number,
  type: HVCabinetType,
): Promise<HVCabinet[]> => {
  const params = { stationId, type };
  return request.get("/common/list/highvoltagecabinets", { params });
};

/**
 * @name 有功功率
 */
export const fetchActivepower = (
  deviceId: string,
  dateRange: [string, string],
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/activepower", { params });
};

/**
 * @name 无功功率
 */
export const fetchReactivepower = (
  deviceId: string,
  dateRange: [string, string],
): Promise<TimeValueResult[]> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/reactivepower", { params });
};

/**
 * @name 线电压
 */
export const fetchLinevoltage = (
  deviceId: string,
  dateRange: [string, string],
): Promise<LineVoltageResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/linevoltage", { params });
};

/**
 * @name 相电压
 */
export const fetchPhasevoltage = (
  deviceId: string,
  dateRange: [string, string],
): Promise<VoltagePhaseResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/phasevoltage", { params });
};

/**
 * @name 电流
 */
export const fetchElectricity = (
  deviceId: string,
  dateRange: [string, string],
): Promise<ElectricityResult> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/electricity", { params });
};

/*功率因数*/
export const fetchKwfactor = (
  deviceId: string,
  dateRange: [string, string],
): Promise<any> => {
  const [startTime, endTime] = dateRangeDec(dateRange);
  const params = { deviceId, startTime, endTime };
  return request.get("device/highvoltage/kwfactor", { params });
};
