<template>
  <template v-if="fullScreen">
    <router-view />
  </template>
  <template v-else>
    <BasicLayout> </BasicLayout>
  </template>
</template>

<script setup lang="ts">
import useStationStore from "@/store/station";
import BasicLayout from "./components/BasicLayout.vue";

const { currentRoute } = useRouter();

console.log("router", currentRoute);

const fullScreen = computed(() => {
  return (
    currentRoute.value.path === "/cer" ||
    currentRoute.value.path === "/cer-html"
  );
});

const stationStore = useStationStore();
stationStore.load();
</script>

<style lang="scss">
html,
body {
  height: 100%;
  background: var(--bg-color);
  // overflow: hidden;
  font-family:
    Microsoft YaHei,
    PingFang SC,
    黑体,
    宋体,
    sans-serif;
  margin: 0px;
  background: #000;
  overflow-y: hidden;
}
body {
  height: 100%;
  overflow-y: hidden;
  min-width: 1366px;
  /*
  &[data-page="overview"] {
    min-width: auto;
  }
  &[data-page="operate-overview"] {
    min-width: auto;
  }
  &[data-page="CER"] {
    min-width: auto;
  }
  */
}
#app {
  height: 100%;
}
.app-wrapper {
  height: 100%;
}
.amap-logo {
  display: none !important;
}
.amap-copyright {
  bottom: -100px;
  display: none;
}
.page-contain {
  padding: 20px;
  background-color: #232324;
}
.chart-tooltip {
  .time {
    margin-bottom: 8px;
  }
  .value-item {
    display: flex;
    & > label {
      font-weight: normal;
      margin-right: 35px;
    }
  }
}
.body-main-fill {
  height: calc(100vh - 130px);
  overflow-y: hidden;
}

.el-tabs.flex-column {
  .el-tabs__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    .el-tab-pane {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
}
.auth-tool-dialog {
  .go-link {
    color: red;
  }
  .description {
    margin-top: 15px;
    margin-left: 15px;
    color: #4b90e2;
    & > li {
      list-style-type: decimal;
      margin-bottom: 10px;
    }
  }
}
.warn-text {
  color: #f04034;
}
.detail-item {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.65);
  margin-bottom: 25px;
}
.el-overlay {
  z-index: 99999;
}
.bg-block {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  border-radius: 2px;
  &.border {
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  &.clear-pd {
    padding: 0;
  }
}
.btn-more-link {
  font-size: 14px;
  color: #51a1ff;
  cursor: pointer;
}
.btn-link {
  color: #51a1ff;
  font-size: 12px;
  cursor: pointer;
  &:active {
    opacity: 0.7;
  }
}
.final-center {
  display: grid;
  align-items: center;
  justify-content: center;
}
.is-scrollable {
  &.direction-y {
    overflow-y: auto;
  }
}
.echarts {
  & > div:nth-child(1) {
    height: auto !important;
  }
}
.x-toolbar {
  white-space: nowrap;
}
</style>
