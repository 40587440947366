<template>
  <div class="search-input">
    <XInput
      :model-value="props.modelValue"
      use-change-event
      @update:model-value="emit('update:modelValue', $event)"
      :placeholder="props.placeholder"
    >
      <template #append>
        <div class="input-group__append">
          <button class="el-button" aria-disabled="false" type="button">
            <Search style="width: 1em; height: 1em; margin-right: 8px" />
          </button>
        </div>
      </template>
    </XInput>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "请输入",
  },
});
const emit = defineEmits(["update:modelValue"]);
</script>

<style lang="scss">
.cx-input {
  display: flex;
  overflow: hidden;
  .input__wrapper {
    flex: 1;
  }
  .input-group__append {
    width: 45px;
    & > button {
      //background: url(./imgs/search.png);
      background-size: 50% 50%;
      background-repeat: no-repeat;
      background-position: 0 8px;
      border: none;
    }
  }
  .el-button {
    background: transparent !important;
  }
}
</style>
