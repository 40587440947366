const screenScale = (width = 1366) => {
  let scale = window.innerWidth / width;
  if (scale > 1) {
    scale = 1;
  }
  const style = document.querySelector("body")?.style || {};
  // @ts-ignore
  document.body.style = style;
  // document.body.style["--scale"] = `scale(${scale})`;
  // document.body.style.transform = `scale(${scale})`;
};

let timer: NodeJS.Timeout = null as any;
window.addEventListener("resize", () => {
  clearTimeout(timer);
  timer = setTimeout(() => {
    screenScale();
  }, 200);
});

screenScale();

export default screenScale;
