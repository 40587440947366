import { MxMessage, MxMessageBox } from "@maxtropy/v-components";
import Axios from "axios";

Axios.defaults.withCredentials = true;

// 请求头信息
Axios.defaults.headers.post["Content-Type"] = "application/json";
const axios = Axios.create({
  timeout: 30000,
  withCredentials: true,
});
axios.interceptors.request.use(
  (config: any) => {
    // @ts-ignore
    config.baseURL = "/api/";
    return config;
  },
  (err: any) => Promise.reject(err),
);

const mode = import.meta.env.MODE;
axios.interceptors.response.use(
  (res: any) => {
    if (res.status !== 200) {
      MxMessage({
        message: "服务器发生未知错误",
        type: "error",
      });
      return;
    }
    const resData = res.data;
    // if (resData.code === 401) {
    //   // 本地开发环境特殊处理
    //   if (mode === "development") {
    //     MxMessageBox.alert(
    //       `
    //       <div class="auth-tool-dialog">
    //         <ul class="description">
    //           <li>【<a class="go-link" href="${resData.errmsg}" target="_blank">点击这里去登录</a>】</li>
    //           <li>登录后 在导向的结果网站上, 打开devtools, 在localStorage里找到token并放到剪切板</li>
    //           <li>点击下面【确定】按扭</li>
    //         </ul>
    //       </div>
    //     `,
    //       "开发者登录",
    //       {
    //         dangerouslyUseHTMLString: true,
    //         beforeClose: () => {
    //           // 关闭之前 把剪切板内容 当成token，设置为cookie，并刷新
    //           navigator.clipboard.readText().then(token => {
    //             if (token && token.length > 10) {
    //               document.cookie = `token=${token};path=/`;
    //               window.location.reload();
    //             }
    //           });
    //         },
    //       },
    //     );
    //     throw new Error("登录错误" + mode);
    //   }
    //   localStorage.setItem("loginCallbackUrl", window.location.href);
    //   window.location.replace(resData.errmsg);
    //   return;
    // }

    if (resData.code === 403) {
      MxMessage({
        message: "没有访问权限",
        type: "error",
      });
      return;
    }
    // 下载传用
    if (res.config.responseType === "arraybuffer") {
      //使用TextDecoder
      const enc = new TextDecoder("utf-8");
      const uint8_msg = new Uint8Array(res.data);
      const encText = enc.decode(uint8_msg);
      if (encText.length < 500) {
        const jsonData = JSON.parse(encText);
        if (!jsonData.success) {
          return MxMessage({
            message: jsonData.errmsg,
            type: "error",
          });
        }
      }

      const fileName = decodeURI(
        res.headers["content-disposition"].split("''")[1] || "导出.xlsx",
      );
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      return res;
    }
    // 指定__originReponse参数返回原始响应
    if (res.config.params && res.config.params.__originReponse) {
      return res.data;
    }
    // 普通api
    if (!res.data.success) {
      MxMessage({
        message: res.data.errmsg,
        type: "error",
      });
      return;
    }
    const data = res.data.data;
    if (!data) return null;

    // 兼容数据结果结构
    return "valueList" in data ? data["valueList"] : data;
  },
  (err: any) => {
    let message = err.message;
    if (err.code === "ERR_NETWORK") {
      message = "服务器或网络出现错误";
    } else if (message && message.includes("404")) {
      message = "未找到的数据服务";
    }
    MxMessage({
      message: message,
      type: "error",
    });
  },
);

export default axios;
