export const accuracy = {
  /*
   *   @function 乘法计算
   *   @description 用于处理两数相乘丢失精度问题
   *   @param {number} arg1 被乘数（接受小数和整数）
   *   @param {number} arg1 乘数（接受小数和整数）
   *   @parameter fix: 乘积保留几位（接受正负整数以及0）
   *   @returns {number} 计算结果
   *   @author zheng
   *   @version 0.0.1
   *   @example
   *    let  res = accMul(2.01,1000,2)
   *    console.log(res) // 2010.00
   */
  numMul: (arg1: number, arg2: number, fix: any, emptyStr: any = "--") => {
    if (undefined === arg1 || null === arg1) return emptyStr;
    if (!parseInt(fix) == fix) {
      return;
    }
    let m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();
    try {
      m += s1.split(".")[1].length;
    } catch (e) {}
    try {
      m += s2.split(".")[1].length;
    } catch (e) {}
    if (m > fix) {
      return (
        Math.round(
          (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
            Math.pow(10, m - fix),
        ) / Math.pow(10, fix)
      );
    } else if (m <= fix) {
      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      ).toFixed(fix);
    } else {
      return (arg1 * arg2).toFixed(fix);
    }
  },
};
